<template>
	<div class="pricing-option-text">
		<p>{{ option.text }}</p>
	</div>
</template>

<script>
	export default {
		name: 'PricingOptionText',
		props: [
			'block',
			'option',
			'bgColor'
		]
	}
</script>

<style lang="scss" scoped>
	.pricing-option-text
	{
		margin: 0;
		padding: 15px;

		text-align: left;

		p
		{
			margin: 0;
			padding: 0;

			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;

			white-space: pre-line;
		}
	}
</style>
