<template>
	<div class="error-message">
		<h1>Er is een fout opgetreden.</h1>
		<p>Klik <nuxt-link to="/" title="Home">hier</nuxt-link> om terug te keren naar de homepagina.</p>
	</div>
</template>

<script>
	export default {
		name: 'ErrorDefault',
		props: [
			'error'
		]
	}
</script>

<style lang="scss" scoped>
  .error-message
  {
    width: 85%;
    max-width: 700px;

    position: absolute;
    top: 50%;
    left: 50%;

    text-align: center;

    transform: translate(-50%, -50%);

    .image
    {
      width: 100%;
      max-width: 700px;

      > img,
      > a > img
      {
        width: 100%;
      }
    }

    > p
    {
      color: #ffffff;
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;

      > a
      {
        text-decoration: underline;
      }
    }
  }
</style>
