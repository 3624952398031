<template>
	<component v-if="story.content.component" :key="story.content._uid" :is="this.story.content.component|dashify" :story="story" :blok="story.content"></component>
</template>

<script>
	import generateCss from '@/assets/js/css';

	const loadData = ({api, version, cacheVersion, path}) => api.get(`cdn/stories${path}`, {
		version: version,
		cv: cacheVersion
	})
	.then((res) => {
		return res.data
	});

	export default {
		async asyncData(context) {
			// Check if we are in the editing mode
			let editMode = false;
			
			if (context.query._storyblok || (
				typeof window !== 'undefined' &&
				window.sessionStorage.getItem('_storyblok_draft_mode')
			)) {
				editMode = true;
			}
			
			context.store.commit('setEditMode', editMode);
			
			const version = process.env.STORYBLOK_VERSION || (editMode || context.isDev ? 'draft' : 'published');
			
			// set path
			let path = '/';
			if (!editMode) {
				if (context.route.path === '/') {
					path = context.store.state.config.root;
				} else {
					path = context.store.state.config.root + context.route.path;
				}
			} else {
				path = context.route.path;
			}
			
			// console.log(context.route.path);
			// console.log(path);
			
			// Show 404 page when page is 'algemeen', 'beheer' and not in editMode
			if (!editMode && path.match(/^\/(algemeen|beheer)/g)) {
				context.error({statusCode: 404, message: 'Failed to receive content form api'});
			}
			
			// Show main page of 'vragen' when subpage is selected in editMode
			// if (editMode && path.match(/^\/algemeen\/vragen\/.*/g)) {
			// 	path = '/algemeen/vragen/';
			// }
			
			// Show main page of 'locaties' when subpage is selected in editMode
			if (editMode && path.match(/^\/beheer\/locaties\/.*/g)) {
				path = '/algemeen/waar-werkt-het/';
			}
			
			// Show main page of 'storingen' when subpage is selected in editMode
			if (editMode && path.match(/^\/beheer\/storingen\/.*/g)) {
				path = '/algemeen/storingen/';
			}
			
			// console.log(path);
			
			// Load the JSON from the API
			let data;
			try {
				data = await loadData({
					api: context.app.$storyapi,
					version: version,
					cacheVersion: context.store.state.cacheVersion,
					path: path
				});
			} catch (e) {
				try {
					path = path.replace(/^\/[^\/]+\//g, '/algemeen/');
					
					data = await loadData({
						api: context.app.$storyapi,
						version: version,
						cacheVersion: context.store.state.cacheVersion,
						path: path
					});
				} catch (res) {
					if (!res.response) {
						console.error(res);
						context.error({statusCode: 404, message: 'Failed to receive content form api'});
					} else {
						console.error(res.response.data);
						context.error({statusCode: res.response.status, message: res.response.data});
					}
				}
			}
			
			return data;
		},
		
		data() {
			return {story: {content: {}}}
		},
		
		beforeRouteEnter(to, from, next) {
			next(vm => vm.$store.commit('route/setTo', to.fullPath));
		},
		
		beforeRouteUpdate(to, from, next) {
			this.$store.commit('route/setFrom', from.fullPath);
			next();
		},
		
		mounted() {
			this.$storybridge.on(['input', 'published', 'change'], (event) => {
				if (event.action === 'input') {
					if (event.story.id === this.story.id) {
						this.story.content = event.story.content
					}
				} else {
					if (event.storyId === this.story.id) {
						loadData({
							api: this.$storyapi,
							version: 'draft',
							cacheVersion: this.$store.state.cacheVersion,
							path: this.story.full_slug
						}).then((data) => {
							this.story = data.story
						}).catch((res) => {
							if (!res.response) {
								console.error(res);
							} else {
								console.error(res.response.data);
							}
						})
					} else {
						window.location.reload()
					}
				}
			})
		},
		head() {
			let story = this.story;

			let links = [];
			let style = [];
			
			if (Object.keys(this.$store.state.personalisation).length !== 0)
			{
				let fonts = {};
				if (this.$store.state.personalisation.primaryFont)
				{
					links.push
					({
						href: this.$store.state.personalisation.primaryFont,
						type: 'text/css',
						rel: 'stylesheet'
					});

					let result = this.$store.state.personalisation.primaryFont.match(/\?family=(.*):/);
					if (result[1])
					{
						fonts.primaryFont = result[1].replace('+', ' ') + ', sans-serif';
					}
				}

				if (this.$store.state.personalisation.secondaryFont)
				{
					links.push
					({
						href: this.$store.state.personalisation.secondaryFont,
						type: 'text/css',
						rel: 'stylesheet'
					});

					let result = this.$store.state.personalisation.secondaryFont.match(/\?family=(.*):/);
					if (result[1])
					{
						fonts.secondaryFont = result[1].replace('+', ' ') + ', sans-serif';
					}
				}

				if (this.$store.state.personalisation.favicon) {
					links.push
					({
						href: this.$store.state.personalisation.favicon,
						type: 'image/x-icon',
						rel: 'icon'
					});
				}
				
				style.push
				({
					cssText: generateCss(this.$store.state.personalisation, fonts),
					type: 'text/css'
				});
			}

			const data = {
				title: story.content.seo_title ? story.content.seo_title : 'Stadsparkeren - ' + story.name,
				meta: [
					{ name: 'description', content: story.content.seo_description ? story.content.seo_description : '' },
					{ name: 'robots', content: story.content.seo_indexable === 'yes' ? 'index, follow' : 'noindex, follow' },
					{ property: 'og:site_name', content: 'Stadsparkeren' },
					{ property: 'og:title', content: story.content.seo_og_title ? story.content.seo_og_title : 'Stadsparkeren - ' + story.name },
					{ property: 'og:description', content: story.content.seo_og_description ? story.content.seo_og_description : '' },
				],
				link: links,
				style: style
			};

			if (story.content.og_image) {
				data.meta.push(
					{property: 'og:image', content: `//img2.storyblok.com/1500x0'${story.content.seo_og_image.replace('//a.storyblok.com', '')}`}
				)
			} else {
				data.meta.push(
					{property: 'og:image', content: `${process.env.BASE_URL}/opengraph/1200x628.jpg`},
					{property: 'og:image:width', content: '1200'},
					{property: 'og:image:height', content: '628'},
					{property: 'og:image', content: `${process.env.BASE_URL}/opengraph/1080x1920.jpg`},
					{property: 'og:image:width', content: '1080'},
					{property: 'og:image:height', content: '1920'},
				)
			}

			return data;
		}
	}
</script>
