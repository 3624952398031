<template>
	<section v-editable="blok" class="blok-full-width-image">

		<div v-if="blok.image" class="image" :style="{paddingTop: imageRatio}">
			<img v-if="editMode"
			     :src="blok.image|resize('1800x0/smart')"
			     :alt="blok.imageAlt"
			/>
			<img v-else
			     :data-src="blok.image|resize('1800x0/smart')"
			     :alt="blok.imageAlt"
			     class="lazyload"
			     data-expand="-100"
			/>
		</div>

		<div v-if="blok.title || blok.text || is_link(blok.link)" class="content">
			<v-container fill-height py-0>
				<v-layout align-center>
					<v-flex xs12 lg8>
            <component v-if="blok.title" :is="blok.heading">
              {{ blok.title|replaceText }}
            </component>

						<p v-if="blok.text" class="text">{{ blok.text|replaceText }}</p>

						<div class="button">
							<s-button-link
								:link="blok.link"
								:linkText="blok.linkText || 'Lees meer'"
							>
								{{ blok.linkText || 'Lees meer'}}
							</s-button-link>
						</div>
					</v-flex>
				</v-layout>
			</v-container>
		</div>

	</section>
</template>

<script>
	import SButtonLink from '@/components/util/SButtonLink'

	export default {
		name: 'FullWidthImage',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SButtonLink: SButtonLink
		},
		data() {
			return {
				editMode: this.$store.state.editMode || false
			}
		},
		computed: {
			imageRatio() {
				const src = this.blok.image;
				const values = !src ? [] : src.split('/');

				if (values.length)
				{
					const size = values[5].split('x');
					return (size[1] / size[0]) * 100 + '%';
				}

				return false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '~vuetify-scss/bootstrap';

	.blok-full-width-image
	{
		display: flex;

		flex-direction: column;

		background-color: #ffffff;

		border-bottom: 1px solid #d3d3d3;

		overflow: hidden;

		.image
		{
			max-width: 100%;

			flex: 0 0 100%;

			position: relative;
			z-index: 2;

			> img
			{
				height: 100%;

				display: block;

				position: absolute;
				top: 50%;
				left: 50%;

				object-fit: cover;

				transform: translate(-50%, -50%);
			}
		}

		.content
		{
			max-width: 100%;

			flex: 0 0 100%;

			margin: 0;
			padding: 30px 0;

			position: relative;
			z-index: 2;

			h1,
      h2,
      h3,
      h4,
      h5,
      h6
			{
				font-size: 2rem;
				font-weight: 400;
				line-height: 1.25;
			}

			.text
			{
				white-space: pre-line;
			}
		}
	}

	@include breakpoint(sm-and-up)
	{
		.blok-full-width-image
		{
			flex-direction: row;

			.content
			{
				transform: translateX(-100%);

				h1,
        h2,
        h3,
        h4,
        h5,
        h6
				{
					color: #ffffff;
					font-size: 2.6rem;
					font-weight: 700;
				}

				.text
				{
					color: #ffffff;
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		.blok-full-width-image
		{
			.content
			{
				h1,
        h2,
        h3,
        h4,
        h5,
        h6
				{
					font-size: 3.2rem;
				}

				.text
				{
					font-size: 1.1rem;
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		.blok-full-width-image
		{
			.content
			{
				h1,
        h2,
        h3,
        h4,
        h5,
        h6
				{
					font-size: 3.6rem;
				}

				.text
				{
					font-size: 1.2rem;
				}
			}
		}
	}
</style>
