import Vue from 'vue';
import qs from 'qs';
import shajs from 'sha.js';
import path from 'path';

const secret = process.env.IMAGE_SERVICE_SECRET;
const imageServiceUrl = process.env.IMAGE_SERVICE_URL;

/**
 * Get a signing hash for image urls
 * @param {String} secret
 * @param {String} filters
 * @param {String} url
 * @return {String}
 */
const getHash = (secret, filters, url) => {
	return shajs('sha256').update(`${secret}${filters}${url}`).digest('hex').substr(0, 10);
};

/**
 * Sign an image url
 * @param {string} source
 * @param {string} format the output format ('jpg', 'png')
 * @param {{}} params
 * @return {string}
 */
const imgService = (source, {format, params} = {}) =>
{
	const encodedParams = qs.stringify(params || {}, {encodeValuesOnly: true});

	const sourceFormat = path.extname(source).substr(1);
	const output = source.replace(/^\/\/a.storyblok.com/, '').replace(/\.[^.]+$/, `.${format || 'jpg'}`);

	return `${imageServiceUrl}/${getHash(secret, encodedParams, output)}/${sourceFormat}/${encodedParams}${output}`;
};

Vue.mixin({methods: {
	imgService: imgService
}});

Vue.filter('imgService', imgService);