<template>
	<section v-editable="blok" class="blok-locations">
		<div class="locations-map">
			<div class="locations-map-widgets">
				<div class="locations-map-widgets-search">
					<div class="locations-map-widgets-search-box">
						<v-autocomplete
							v-model="city"
							:items="citiesAutocomplete"
							placeholder="Zoeken op gemeente"
							prepend-inner-icon="place"
							item-text="name"
							return-object
							hide-details
							single-line
							clearable
							outlined
						></v-autocomplete>
					</div>
					<div class="locations-map-widgets-filters-toggle-button">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn icon large v-on="on" @click="toggleFilters()">
									<v-icon>filter_list</v-icon>
								</v-btn>
							</template>
							<span>Filteren</span>
						</v-tooltip>
					</div>
					<div v-if="visibleCities.length" class="locations-map-widgets-back-button">
						<v-tooltip top nudge-top>
							<template v-slot:activator="{ on }">
								<v-btn icon large v-on="on" @click="backToOverview()">
									<v-icon>arrow_back</v-icon>
								</v-btn>
							</template>
							<span>Terug naar overzicht</span>
						</v-tooltip>
					</div>
				</div>
				<div  class="locations-map-widgets-back-button">
					<v-btn icon large @click="toggleFilters()">
						<v-icon>arrow_back</v-icon>
					</v-btn>
				</div>
				<div class="locations-map-widgets-filters" :class="{open: showFilters }">
					<p class="h3-title">Filteren</p>

					<div class="locations-map-widgets-filters-filter">
						<ul>
							<li>
								<v-checkbox
									v-model="filters.type"
									label="Parkeergarage/-terrein"
									value="garage"
									hide-details
								></v-checkbox>
								<ul>
									<li>
										<v-checkbox
											v-model="filters.garage"
											:disabled="!filters.type.includes('garage')"
											label="Kenteken"
											value="license"
											hide-details
										></v-checkbox>
									</li>
									<li>
										<v-checkbox
											v-model="filters.garage"
											:disabled="!filters.type.includes('garage')"
											label="Pas"
											value="pass"
											hide-details
										></v-checkbox>
									</li>
								</ul>
							</li>
							<li>
								<v-checkbox
									v-model="filters.type"
									label="Straatparkeren"
									value="street"
									hide-details
								></v-checkbox>
								<ul>
									<li>
										<v-checkbox
											v-model="filters.street"
											:disabled="!filters.type.includes('street')"
											label="Pas"
											value="pass"
											hide-details
										></v-checkbox>
									</li>
									<li>
										<v-checkbox
											v-model="filters.app"
											:disabled="!filters.type.includes('street')"
											label="App"
											hide-details
										></v-checkbox>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="locations-map-gmap">
				<div class="map" id="gmap" ref="map"></div>
			</div>

			<div v-if="visibleCities.length" class="locations-map-results-wrapper" :class="{open: showResults }">
				<div class="locations-map-results-toggle-button">
					<span @click="toggleResults()"></span>
				</div>

				<div class="locations-map-results">
					<article v-for="(city, index) in visibleCities" :key="index" class="locations-map-result">
						<div class="locations-map-result-city" :class="{open: showCity === city._uid}">
							<div class="content">
								<p class="h3-title">
									{{ city.name }}
								</p>

								<div class="text">

									<p v-if="city.streetparking === 'yes'">{{ city.shortInformation || 'Parkeren op straat met de Stadsparkeren app of via je persoonlijke pagina.' }}</p>

									<p v-if="city.information" class="specific">
										<a href="javascript:void(0)" title="Bekijk specifieke gemeente informatie" @click="toggleCity(city)">
											Bekijk specifieke gemeente informatie
										</a>
									</p>

									<div class="extra">
										<s-markdown
											v-if="city.information"
											:tag="'div'"
											:value="city.information"
										/>

										<div class="button">
											<s-button-link
												v-if="city.link"
												:link="city.link"
												:linkText="'Meer gemeente informatie'"
												class="small"
											>
												Meer gemeente informatie
											</s-button-link>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-for="(area, index) in city.areas" :key="index" :id="'stpr-' + area._uid" class="locations-map-result-area" :class="{active: activeArea === area._uid}" @mouseover="mouseOverArea(area)" @mouseout="mouseOutArea(area)">
							<div class="content">
								<h4>{{ area.name }}</h4>

								<p v-if="area.address || area.zipcode || area.city" class="address">
									<strong v-if="area.address">{{ area.address }} - </strong>
									<strong v-if="area.zipcode">{{ area.zipcode }}, </strong>
									<strong v-if="area.city">{{ area.city }}</strong>
								</p>

								<s-markdown
									v-if="area.information"
									:tag="'div'"
									:value="area.information"
									class="text"
								/>

								<p v-if="area.type === 'garage'" class="notification">
									<strong>Let op! Neem buiten openingstijden altijd uw pas mee.</strong>
								</p>

								<div class="buttons">
									<s-button-link
										v-if="area.address && area.zipcode && area.city"
										:link="'https://maps.google.nl/maps?daddr=' + address(area)"
										:linkText="'Bekijk route'"
										class="small"
									>
										Bekijk route
									</s-button-link>
									<s-button-link
										v-for="(button, index) in area.buttons" :key="index"
										:link="button.link"
										:linkText="button.linkText || 'Lees meer'"
										class="small"
									>
										{{ button.linkText || 'Lees meer' }}
									</s-button-link>
								</div>
							</div>
						</div>
					</article>
				</div>
			</div>
		</div>
		<div class="locations-list">
			<v-container grid-list-xl>
				<v-layout wrap>
					<v-flex xs12 lg10 xl8 offset-lg1 offset-xl2>
						<div class="locations-list-title">
							<p class="h2-title">
								Stadsparkeren gemeentes
								<a href="javascript:void(0);" class="print" title="Print alle gemeentes" @click="printNow()">
									<span class="material-icons">print</span>
									Print alle gemeentes
								</a>
							</p>
						</div>
						<div class="locations-list-results">
							<v-layout wrap>
								<v-flex v-for="(column, index) in citiesList" :key="index" xs12 sm6 lg4>
									<div class="item">
										<div class="content">
											<ul>
												<li v-for="city in column" :key="city.uuid">
													<a href="javascript:void(0);" :title="city.name" @click="zoomCity(city, true)">
                            <span class="name">{{ city.name }}</span>

                            <span class="type">
                              <img
                                v-if="city.garage"
                                :src="$store.state.personalisation.mapsGarageMarker"
                                title="Garage parkeren"
                              />
                              <img
                                v-if="city.street"
                                :src="$store.state.personalisation.mapsStreetMarker"
                                title="Straat parkeren"
                              />
                            </span>

                            <span class="payment">
                              <span
                                v-if="city.license"
                                class="material-icons icon"
                                title="Kentekenherkenning"
                              >
                                directions_car
                              </span>
                              <span
                                v-if="city.pass"
                                class="material-icons icon"
                                title="Pas-parkeren"
                              >
                                credit_card
                              </span>
                              <span
                                v-if="city.app"
                                class="material-icons icon"
                                title="App-parkeren"
                              >
                                phone_iphone
                              </span>
                            </span>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</v-flex>
							</v-layout>
						</div>
					</v-flex>
				</v-layout>
			</v-container>
		</div>
	</section>
</template>

<script>
	import googleMapsApiLoader from 'google-maps-api-loader';
	import googleMapsMarkerClusterer from '@google/markerclustererplus';

	import SMarkdown from '@/components/util/SMarkdown';
	import SButtonLink from '@/components/util/SButtonLink'

	export default {
		name: 'Locations',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SMarkdown: SMarkdown,
			SButtonLink: SButtonLink
		},
		data() {
			return {
				cities: [],

				clusterers: [],
				other: [],

				visibleCities: [],
				activeArea: false,

				google: null,
				map: null,

				originalBounds: null,

				mapOptions: {
					mapType: 'roadmap',
					center: {
						lat: 52.092876,
						lng: 5.104480
					},
					zoom: 8,
					mapTypeControl: false,
					fullscreenControl: false,
					streetViewControl: false
				},

				clusterOptions: {
					minimumClusterSize: 1,
					maxZoom: 12,
					gridSize: 1000,
					avarageCenter: true,
					ignoreHidden: true
				},

				city: {},
				filters: {
					type: [
						'garage',
						'street'
					],
					garage: [
						'license',
						'pass'
					],
					street: [
						'pass'
					],
					app: true
				},

				showFilters: false,
				showResults: false,
				showCity: false,

				isMobile: false
			}
		},
		async mounted()
		{
			this.handleResize();

			this.showFilters = !this.isMobile;

			await this.loadCities();
			await this.initMap();
		},
		created()
		{
			if (process.browser) {
				window.addEventListener('resize', this.handleResize);
			}
		},
		destroyed()
		{
			if (process.browser) {
				window.removeEventListener('resize', this.handleResize);
			}
		},
		watch: {
			city: {
				handler(value)
				{
					if (!value)
					{
						return;
					}

					this.zoomCity(value, false);
				}
			},
			filters: {
				handler(filters)
				{
					this.filterMarkers();
					this.filterResults();
				},
				deep: true
			}
		},
		computed: {
			citiesAutocomplete()
			{
				return this.cities
					.filter(city => city.areas)
					.sort((a, b) => (a.name > b.name) ? 1 : -1);
			},
			citiesList()
			{
				const list = [];

				(this.cities || []).forEach(city =>
				{
					list.push
					({
						...city,
						garage: city.areas.filter(area => area.type === 'garage').length > 0,
						street: city.streetparking === 'yes',
            license: city.areas.filter(area => area.payment.includes('license')).length > 0,
            pass: city.areas.filter(area => area.payment.includes('pass')).length > 0,
            app: city.streetparking === 'yes'
					});
				});

				list.sort((a, b) => {
					if (a.name < b.name) { return -1; }
					if (a.name > b.name) { return 1; }
					return 0;
				});

				return this.chunk(list, 3);
			}
		},
		methods: {
			async loadCities()
			{
				const res = await this.$storyapi.get('cdn/stories', {
					starts_with: 'beheer/locaties',
					is_startpage: 0,
					per_page: 100,
					filter_query : {
						component: {
							in: 'page-location-province'
						}
					},
					sort_by: 'position:asc'
				});

				if (!res.data.stories) {
					console.error('Failed to load resource');
				}

				this.cities = res.data.stories
					.flatMap(story => story.content.cities);
			},
			async initMap()
			{
				try
				{
					const google = await googleMapsApiLoader({
						apiKey: process.env.GOOGLE_MAPS_API_KEY
					});

					const map = new google.maps.Map(this.$refs.map, {
						...this.mapOptions,
						zoomControlOptions: {
							position: google.maps.ControlPosition.LEFT_BOTTOM
						}
					});

					map.addListener('idle', this.mapIdle);

					this.addMarkers(map);

					this.google = google;
					this.map = map;
				}
				catch (e)
				{
					console.error(e);
				}
			},
			mapIdle()
			{
				this.filterResults();
			},
			addMarkers(map)
			{
				const markerClusterers = [];

				const bounds = new google.maps.LatLngBounds();

				(this.cities || []).forEach(city =>
				{
					if (city.areas.length)
					{
						const markers = [];

						(city.areas || []).forEach(area =>
						{
							for (let location of area.locations)
							{
								const marker = this.createMarker(city, area, location);
								if (marker)
								{
									markers.push(marker);

									bounds.extend(marker.getPosition());
								}
							}
						});

						const clusterer = this.createClusterer(map, markers, city);
						if (clusterer)
						{
							markerClusterers.push(clusterer);
						}
					}
					else
					{
						const marker = this.createOtherMarker(map, city);
						if (marker)
						{
							this.other.push(marker);

							bounds.extend(marker.getPosition());
						}
					}
				});

				this.clusterers = markerClusterers;

				this.originalBounds = bounds;

				map.fitBounds(bounds);

				if (!this.isMobile && this.showResults) {
					map.panBy(250, 0);
				}
			},
			createMarker(city, area, location)
			{
				if (!location.coordinates && !(location.coordinates.latitude || location.coordinates.longitude)) {
          console.log(city.name, area.name, location.coordinates.latitude, location.coordinates.longitude);
          return false;
				}

				const icon = this.createMarkerIcon(area);

				const marker = new google.maps.Marker({
					icon: icon,
					position: {
						lat: location.coordinates.latitude,
						lng: location.coordinates.longitude
					},
					city: city,
					area: area,
					location: location
				});

				this.addMarkerEventListeners(marker, area, location);

				return marker;
			},
			createMarkerIcon(area)
			{
				const personalisation = this.$store.state.personalisation;

				if (area.type === 'garage')
				{
					return  {
						url: personalisation.mapsGarageMarker,
						size: new google.maps.Size(45, 60),
						scaledSize: new google.maps.Size(45, 60),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(22.5, 60)
					};
				}

				return {
					url: personalisation.mapsStreetMarker,
					size: new google.maps.Size(45, 60),
					scaledSize: new google.maps.Size(45, 60),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(22.5, 60)
				};
			},
			addMarkerEventListeners(marker, area, location)
			{
				const toolTip = new google.maps.InfoWindow({
					content: '<div class="tooltip">' + (!!location.name ? location.name : area.name) + '</div>'
				});

				marker.addListener('mouseover', (e) =>
				{
					toolTip.open(this.map, marker);
				});

				marker.addListener('mouseout', (e) =>
				{
					if (typeof toolTip !== 'undefined') {
						toolTip.close();
					}
				});

				marker.addListener('click', (e) =>
				{
					if (this.activeArea === marker.area._uid)
					{
						this.activeArea = false;
					}
					else
					{
						this.showResults = true;

						this.activeArea = marker.area._uid;

						this.$scrollTo(`#stpr-${this.activeArea}`, 500, {
							container: '.locations-map-results',
							easing: 'ease-in',
							offset: -107
						});
					}
				});
			},
			createOtherMarker(map, city)
			{
				if (!city.coordinates || !city.coordinates.latitude || !city.coordinates.longitude) {
          console.log(city.name, city.coordinates.latitude, city.coordinates.longitude);
					return false;
				}
				const icon = this.createOtherMarkerIcon(city);

				const marker = new google.maps.Marker
				({
					map: map,
					icon: icon,
					position: {
						lat: city.coordinates.latitude,
						lng: city.coordinates.longitude
					},
					city: city
				});

				this.addOtherMarkerEventListeners(marker, city);

				return marker;
			},
			createOtherMarkerIcon(city)
			{
				const personalisation = this.$store.state.personalisation;

				if (city.type === 'yes')
				{
					return {
						url: personalisation.mapsStadsparkerenClusterMarker,
						size: new google.maps.Size(33.912, 50),
						scaledSize: new google.maps.Size(33.912, 50),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(16.5, 50)
					};
				}

				return {
					url: personalisation.mapsOtherClusterMarker,
					size: new google.maps.Size(33, 33),
					scaledSize: new google.maps.Size(33, 33),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(16.5, 16.5)
				};
			},
			addOtherMarkerEventListeners(marker, city)
			{
				const toolTip = new google.maps.InfoWindow({
					content: '<div class="tooltip">' + city.name + '</div>'
				});

				marker.addListener('mouseover', (e) =>
				{
					toolTip.open(this.map, marker);
				});

				marker.addListener('mouseout', (e) =>
				{
					if (typeof toolTip !== 'undefined') {
						toolTip.close();
					}
				});

				marker.addListener('click', (e) =>
				{
					this.showResults = true;

					this.map.setZoom(15);

					this.map.setCenter({
						lat: city.coordinates.latitude,
						lng: city.coordinates.longitude
					});

					if (!this.isMobile && this.showResults) {
						this.map.panBy(250, 0);
					}
				});
			},
			createClusterer(map, markers, city)
			{
				if (markers.length === 0) {
					return false;
				}

				const styles = this.getClustererStyle(city);

				const markerClusterer = new googleMapsMarkerClusterer(map, markers, {
					...this.clusterOptions,
					styles: styles
				});

				this.addClustererEventListeners(markerClusterer, city);

				return markerClusterer;
			},
			getClustererStyle(city)
			{
				const personalisation = this.$store.state.personalisation;

				if (city.type === 'yes')
				{
					return [{
						url: personalisation.mapsStadsparkerenClusterMarker,
						height: 50,
						width: 33.912,
						iconAnchor: [
							16.5,
							50
						],
						textSize: 0.001
					}];
				}

				return  [{
					url: personalisation.mapsOtherClusterMarker,
					height: 33,
					width: 33,
					iconAnchor: [
						16.5,
						16.5
					],
					textSize: 0.001
				}];
			},
			addClustererEventListeners(clusterer, city)
			{
				const toolTip = new google.maps.InfoWindow({
					content: '<div class="tooltip">' + city.name + '</div>',
					pixelOffset: city.type === 'yes' ?
						new google.maps.Size(0, -25) :
						new google.maps.Size(0, -16.5)
				});

				clusterer.addListener('mouseover', (cluster) =>
				{
					toolTip.setPosition(cluster.getCenter());
					toolTip.open(this.map);
				});

				clusterer.addListener('mouseout', (cluster) =>
				{
					if (typeof toolTip !== 'undefined') {
						toolTip.close();
					}
				});

				clusterer.addListener('click', (cluster) =>
				{
					if (typeof toolTip !== 'undefined') {
						toolTip.close();
					}

					if (clusterer.getZoomOnClick())
					{
						this.showResults = true;

						const bounds = new google.maps.LatLngBounds();

						(cluster.getMarkers() || []).forEach(marker =>
						{
							bounds.extend(marker.getPosition());
						});

						this.map.setOptions({maxZoom: 15});
						this.map.fitBounds(bounds);
            this.map.setOptions({maxZoom: 20});

						if (!this.isMobile && this.showResults) {
							this.map.panBy(250, 0);
						}
					}
				});
			},
			filterMarkers()
			{
				this.clusterers
					.forEach(clusterer =>
					{
						const markers = clusterer.getMarkers();
						markers.forEach(marker =>
						{
							//let addType = this.filters.type.includes(marker.area.type);

							let addType =
								(this.filters.type.includes('garage') && marker.area.type === 'garage') ||
								(this.filters.type.includes('street') && marker.city.streetparking === 'yes');

							let addPayment = false;
							if (addType)
							{
								marker.area.payment.forEach(payment =>
								{
									if (this.filters.type.includes(marker.area.type) && this.filters[marker.area.type].includes(payment)) {
										addPayment = addPayment || true;
									}
								});
							}

							if (addType && (addPayment || this.filters.app)) {
								this.showMarker(marker);
							} else {
								this.hideMarker(marker);
							}
						});

						clusterer.repaint();
					});

				this.other
					.forEach(marker =>
					{
						if (this.filters.type.includes('street') && marker.city.streetparking === 'yes' && this.filters.app) {
							this.showMarker(marker);
						} else {
							this.hideMarker(marker);
						}
					});
			},
			filterResults()
			{
				if (this.map.getZoom() <= this.clusterOptions.maxZoom) {
					this.visibleCities = [];
					return
				}

				let cities = [];
				let areas = [];

				const bounds = this.map.getBounds();

				this.clusterers
					.forEach(clusterer =>
					{
						const markers = clusterer.getMarkers();
						markers.forEach(marker =>
						{
							if (bounds.contains(marker.getPosition()) && marker.getVisible()) {
								areas.push(marker.area._uid);
							}
						});
					});

				cities = this.cities
					.map(city =>
					{
						return {
							...city,
							areas: city.areas.filter(area => areas.includes(area._uid))
						};
					})
					.filter(city => city.areas.length);


				this.other
					.forEach(marker =>
					{
						if (bounds.contains(marker.getPosition()) && marker.getVisible()) {
							cities.push(marker.city);
						}
					});

				this.visibleCities = cities.sort((a, b) => {
					return b.areas.length - a.areas.length || (a.name > b.name) ? 1 : -1;
				});
			},
			showMarker(marker)
			{
				marker.setVisible(true);
			},
			hideMarker(marker)
			{
				marker.setVisible(false);
			},
			zoomCity(city, scrollTo)
			{
				const bounds = new google.maps.LatLngBounds();

				if (city.areas.length)
				{
					(city.areas || []).forEach(area =>
					{
						(area.locations || []).forEach(location =>
						{
							bounds.extend
							({
								lat: location.coordinates.latitude,
								lng: location.coordinates.longitude
							});
						})
					});

          this.map.setOptions({maxZoom: 15});
          this.map.fitBounds(bounds);
          this.map.setOptions({maxZoom: 20});
				}
				else
				{
					this.map.setZoom(15);

					this.map.setCenter({
						lat: city.coordinates.latitude,
						lng: city.coordinates.longitude
					});
				}

				if (!this.isMobile && this.showResults) {
					this.map.panBy(250, 0);
				}

				if (scrollTo) {
					this.$scrollTo(`#gmap`, 500, {
						easing: 'ease-in',
						offset: -100
					});
				}
			},
			toggleFilters()
			{
				this.showFilters = !this.showFilters;
			},
			toggleResults()
			{
				this.showResults = !this.showResults;
			},
			toggleCity(city)
			{
				if (this.showCity === city._uid) {
					this.showCity = false;
				} else {
					this.showCity = city._uid;
				}
			},
			backToOverview()
			{
				this.map.fitBounds(this.originalBounds);
			},
			mouseOverArea(area)
			{
				const marker = this.clusterers
					.flatMap(clusterer => {
						 return clusterer.getMarkers();
					})
					.find(marker => area._uid === marker.area._uid);

				if (marker) {
					google.maps.event.trigger(marker, 'mouseover');
				}
			},
			mouseOutArea(area)
			{
				const marker = this.clusterers
					.flatMap(clusterer => {
						return clusterer.getMarkers();
					})
					.find(marker => area._uid === marker.area._uid);

				if (marker) {
					google.maps.event.trigger(marker, 'mouseout');
				}
			},
			handleResize()
			{
				if (window.innerWidth > 1263)
				{
					this.isMobile = false;
				}
				else
				{
					this.isMobile = true;
				}
			},
			address(area)
			{
				return `${area.address}, ${area.zipcode} ${area.city}`;
			},
			printNow()
			{
				if (window.Tawk_API) {
					window.Tawk_API.hideWidget();
				}

        try {
          document.execCommand('print', false, null)
        } catch {
          window.print()
        }

				if (window.Tawk_API) {
					window.Tawk_API.showWidget();
				}
			},
			chunk(items, size)
			{
				const result = [];

				const total = items.length;
				const count = Math.ceil(total / size);

				for (let i = 0; i < size; i++)
				{
					const start = i * count;
					const end = (i + 1) * count;

					result.push(items.slice(start, end));
				}

				return result;
			}
		}
	}
</script>

<style lang="scss">
	@import '~vuetify-scss/bootstrap';

	.blok-locations
	{
		height: 100%;

		position: relative;

		background-color: #ffffff;

		.locations-map
		{
			height: 100%;
			width: 100%;

			position: relative;
			z-index: 1;

			.locations-map-gmap
			{
				height: calc(100vh - 160px);
				min-height: 600px;
				width: 100%;

				position: relative;
				z-index: 1;

				text-align: center;

				border: 1px solid #d3d3d3;

				.map
				{
					height: 100%;
					width: 100%;
				}
			}

			.locations-map-widgets
			{
				width: calc(100% - 20px);

				position: absolute;
				top: 10px;
				left: 10px;
				z-index: 2;

				.locations-map-widgets-search
				{
					width: 100%;

					display: flex;
					align-items: center;

					padding-right: 10px;

					position: absolute;
					top: 0;
					left: 0;
					z-index: 3;

					background-color: #ffffff;

					border-radius: 5px;

					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

					.locations-map-widgets-search-box
					{
						flex: 1 1 auto;

						.v-text-field--outlined fieldset
						{
							border-color: transparent !important;
							border-width: 1px !important;
						}
					}

					.locations-map-widgets-filters-toggle-button
					{
						flex: 0 1 auto;
					}
				}

				.locations-map-widgets-filters
				{
					width: 100%;

					margin: 0;
					padding: 20px;

					position: absolute;
					top: 40px;
					left: 0;
					z-index: 2;

					background-color: #ffffff;

					border-radius: 5px;

					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

					visibility: hidden;
					opacity: 0;

					transition: top 0.2s cubic-bezier(0.4, 0, 0.2, 1),
								visibility 0.2s cubic-bezier(0.4, 0, 0.2, 1),
								opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);

					&.open
					{
						top: 68px;

						visibility: visible;
						opacity: 1;
					}

					.locations-map-widgets-filters-filter
					{
						margin: 0;
						padding: 0;

						transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);

						> ul
						{
							margin: 0;
							padding: 0;

							list-style: none;

							> li
							{
								> ul
								{
									list-style: none;
								}
							}
						}
					}
				}
			}

			.locations-map-results-wrapper
			{
				width: 100%;

				position: relative;

				background-color: #ffffff;

				border-radius: 5px;

				.locations-map-results-toggle-button
				{
					display: none;
					visibility: hidden;
				}

				.locations-map-results
				{
					height: 100%;

					margin: 20px 0;

					.locations-map-result
					{
						.locations-map-result-city
						{
							margin: 0;
							padding: 20px;

							position: relative;
							top: 0;
							z-index: 2;

							background-color: #f5f5f5;

							border: 1px solid #d3d3d3;

							.content
							{
								.h3-title
								{
									margin: 0;
									padding: 0;

									font-weight: 400;

									> span
									{
										margin: 0;
										padding: 0 25px 0 0;

										display: inline-block;

										position: relative;

										font-size: 0.9rem;
										font-weight: 400;

										cursor: pointer;

										&:before
										{
											content: 'keyboard_arrow_down';

											position: absolute;
											top: 50%;
											right: 0;

											font-family: 'Material Icons';
											font-size: 1.4rem;
											line-height: 1;

											transform: translate(0%, -50%);
										}
									}
								}

								.text
								{
									font-size: 0.8rem;

									> p
									{
										margin-bottom: 0;
									}

									> .specific
									{
										> a
										{
											display: block;

											position: relative;

											font-size: 0.8rem;
											font-weight: 400;

											cursor: pointer;

											&:before
											{
												content: 'keyboard_arrow_down';

												position: absolute;
												top: 50%;
												right: 0;

												font-family: 'Material Icons';
												font-size: 1.4rem;
												line-height: 1;

												transform: translate(0%, -50%);
											}
										}
									}

									> .extra
									{
										max-height: 0;

										margin: 0;
										padding: 0;

										overflow: hidden;

										transition:
											max-height 0.2s cubic-bezier(0.4, 0, 0.2, 1),
											padding 0.2s cubic-bezier(0.4, 0, 0.2, 1);

										.button
										{
											margin-top: 25px;
										}
									}
								}
							}

							&.open
							{
								.content
								{
									.text
									{
										> .specific > a:before
										{
											content: 'keyboard_arrow_up';
										}

										> .extra
										{
											max-height: 1000px;

											padding-top: 10px;
										}
									}
								}
							}
						}

						.locations-map-result-area
						{
							width: 100%;

							position: relative;
							z-index: 1;

							background-color: #ffffff;

							border-left: 4px solid transparent;
							border-bottom: 1px solid #d3d3d3;

							overflow: hidden;

							cursor: pointer;

							&.active
							{
								background-color: #f9f9f9;
							}

							&:hover
							{
								border-left: 4px solid #d3d3d3;
							}

							&:last-child
							{
								border-bottom: 1px solid #d3d3d3;
							}

							.content
							{
								padding: 20px;

								> p.address,
								> p.notification
								{
									font-size: 0.8rem;
								}

								.text
								{
									font-size: 0.9rem;
								}

								.buttons .btn
								{
									margin: 0 10px 10px 0;
								}
							}
						}
					}

				}
			}
		}

		.locations-list
		{
			margin: 0;
			padding: 12px 0;

			.locations-list-title
			{
				.h2-title
				{
					position: relative;

					.print
					{
						margin: 0;
						padding-left: 25px;

						position: relative;

						font-size: 0.9rem;

						span
						{
							position: absolute;
							top: 50%;
							left: 0;

							font-size: 0.9rem;

							transform: translateY(-50%);
						}
					}
				}
			}

			.locations-list-results
			{
				ul
				{
					margin: 0;
					padding: 0;

					list-style: none;

					li
					{
						cursor: pointer;

						border-bottom: 1px solid #eeeeee;

						a
						{
							display: block;

              line-height: 30px;

							&:hover,
							&:focus
							{
                span.name
                {
                  text-decoration: underline;
                }
							}

							span.name
							{
								min-width: 180px;

								display: inline-block;

                font-size: 0.8rem;
							}

              span.type
              {
                display: inline-block;

                img
                {
                  width: 20px;

                  margin-right: 3px;

                  vertical-align: middle;
                }
              }

              span.payment
              {
                display: inline-block;

                span.icon
                {
                  height: 20px;
                  width: 20px;

                  margin-right: 3px;

                  font-size: 1rem;

                  text-align: center;

                  vertical-align: middle;
                }
              }
						}
					}
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		.blok-locations
		{
			.locations-list
			{
				.locations-list-title
				{
					.h2-title
					{
						position: relative;

						.print
						{
							margin: 0;
							padding-left: 25px;

							position: absolute;
							top: 50%;
							right: 0;

							font-size: 0.9rem;

							transform: translateY(-50%);

							span
							{
								position: absolute;
								top: 50%;
								left: 0;

								font-size: 0.9rem;

								transform: translateY(-50%);
							}
						}
					}
				}
			}
		}
	}

	@include breakpoint(lg-and-up)
	{
		.blok-locations
		{
			.locations-map
			{
				overflow: hidden;

				.locations-map-gmap
				{
					min-height: 900px;
				}

				.locations-map-widgets
				{
					.locations-map-widgets-search
					{
						width: 400px;
					}

					.locations-map-widgets-filters
					{
						width: 400px;
					}
				}

				.locations-map-results-wrapper
				{
					height: calc(100% - 20px);
					width: 500px;

					position: absolute;
					top: 10px;
					right: -500px;
					bottom: 10px;
					z-index: 2;

					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

					transition: right 0.5s cubic-bezier(0.4, 0, 0.2, 1);

					&.open
					{
						right: 10px;

						.locations-map-results-toggle-button span:before
						{
							content: 'keyboard_arrow_right';
						}
					}

					.locations-map-results-toggle-button
					{
						height: 50px;
						width: 50px;

						display: block;

						position: absolute;
						top: 20px;
						left: -50px;
						z-index: -1;

						background-color: #f5f5f5;

						border-radius: 5px 0 0 5px;

						box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

						visibility: visible;

						span
						{
							height: 100%;
							width: 100%;

							display: block;

							cursor: pointer;

							&:before
							{
								content: 'keyboard_arrow_left';

								position: absolute;
								top: 50%;
								left: 50%;

								font-family: 'Material Icons';
								font-size: 1.6rem;
								line-height: 1;

								transform: translate(-50%, -50%);
							}
						}
					}

					.locations-map-results
					{
						height: 100%;

						margin: 0;

						overflow: auto;

						border-radius: 5px;

						.locations-map-result
						{
							.locations-map-result-city
							{
								position: sticky;

								border: none;
								border-bottom: 1px solid #d3d3d3;
							}
						}
					}
				}
			}
		}
	}

	.gm-style-iw
	{
		margin: 0;
		padding: 6px 18px !important;

		.gm-style-iw-d
		{
			overflow: hidden !important;

			.tooltip
			{
				height: 100%;
				width: 100%;

				font-size: 0.8rem;
				font-weight: 400;

				white-space: nowrap;
			}
		}

		button
		{
			display: none !important;
		}
	}
</style>
