<template>
	<div class="error-message">
    <div class="image">
      <nuxt-link to="/" title="Home">
        <img src="/404.png" title="Stadsparkeren 404 error" />
      </nuxt-link>
    </div>
		<p>Deze zoekopdracht parkeren we even, want we zijn nu toch echt de weg kwijt.</p>
		<p>Klik op de auto om terug te keren.</p>
	</div>
</template>

<script>
	export default {
		name: 'Error404',
		props: [
			'error'
		]
	}
</script>

<style lang="scss" scoped>
	.error-message
	{
		width: 85%;
		max-width: 700px;

		position: absolute;
		top: 50%;
		left: 50%;

		text-align: center;

		transform: translate(-50%, -50%);

    .image
    {
      width: 100%;
      max-width: 700px;

      > img,
      > a > img
      {
        width: 100%;
      }
    }

		> p
    {
      color: #ffffff;
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;

      > a
      {
        text-decoration: underline;
      }
    }
	}
</style>
