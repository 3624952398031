const middleware = {}

middleware['headers'] = require('../middleware/headers.js')
middleware['headers'] = middleware['headers'].default || middleware['headers']

middleware['ipRestrictions'] = require('../middleware/ipRestrictions.js')
middleware['ipRestrictions'] = middleware['ipRestrictions'].default || middleware['ipRestrictions']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['redirectWww'] = require('../middleware/redirectWww.js')
middleware['redirectWww'] = middleware['redirectWww'].default || middleware['redirectWww']

middleware['setCacheVersion'] = require('../middleware/setCacheVersion.js')
middleware['setCacheVersion'] = middleware['setCacheVersion'].default || middleware['setCacheVersion']

middleware['setConfig'] = require('../middleware/setConfig.js')
middleware['setConfig'] = middleware['setConfig'].default || middleware['setConfig']

middleware['setLinks'] = require('../middleware/setLinks.js')
middleware['setLinks'] = middleware['setLinks'].default || middleware['setLinks']

middleware['setPersonalisation'] = require('../middleware/setPersonalisation.js')
middleware['setPersonalisation'] = middleware['setPersonalisation'].default || middleware['setPersonalisation']

middleware['setSettings'] = require('../middleware/setSettings.js')
middleware['setSettings'] = middleware['setSettings'].default || middleware['setSettings']

middleware['setVersion'] = require('../middleware/setVersion.js')
middleware['setVersion'] = middleware['setVersion'].default || middleware['setVersion']

export default middleware
