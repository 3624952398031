<template>
	<div class="pricing-option-title" :style="{backgroundColor: bgColor && bgColor.color}">
		<p class="p-title">{{ option.price }}</p>
		<p>{{ option.text }}</p>
	</div>
</template>

<script>
	export default {
		name: 'PricingOptionTitle',
		props: [
			'block',
			'option',
			'bgColor'
		]
	}
</script>

<style lang="scss" scoped>
	.pricing-option-title
	{
		margin-bottom: 10px;
		padding: 15px;

		text-align: center;

		.p-title
		{
			color: #ffffff;
			font-size: 3rem;
			font-weight: 700;
			line-height: 1.25;
		}

		p
		{
			color: #ffffff;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;

			white-space: pre-line;
		}
	}
</style>
