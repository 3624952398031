<template>
	<section v-editable="blok" v-if="is_link(blok.link)" :class="blockClass">
		<div class="button">
			<s-button-link
				:link="blok.link"
				:linkText="blok.linkText || 'Lees meer'"
				:linkStyle="buttonStyle"
			>
				{{ blok.linkText || 'Lees meer'}}
			</s-button-link>
		</div>
	</section>
</template>

<script>
	import SButtonLink from '@/components/util/SButtonLink';

	export default {
		name: 'Button',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SButtonLink: SButtonLink
		},
		computed: {
			blockClass() {
				return `blok-button align-text-${this.blok.align}`;
			},
			buttonStyle() {
				if (this.blok.backgroundColor === 'primary') {
					return {
						backgroundColor: this.$store.state.personalisation.primaryColor && this.$store.state.personalisation.primaryColor.color
					};
				} else if (this.blok.backgroundColor === 'secondary') {
					return {
						backgroundColor: this.$store.state.personalisation.secondaryColor && this.$store.state.personalisation.secondaryColor.color
					};
				}
				
				return null;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.blok-button
	{
		margin: 0;
		padding: 12px 0;

		text-align: left;

		&.align-text-center
		{
			text-align: center;
		}
	}
</style>
