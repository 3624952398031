<template>
	<section v-editable="blok" v-if="blok.table" class="blok-table">
		<div class="table">
			<table>
				<thead>
					<tr>
						<th v-for="(th, index) in blok.table.thead" :key="index">{{ th.value }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(tr, index) in blok.table.tbody" :key="index">
						<td v-for="(td, index) in tr.body" :key="index">{{ td.value }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Table',
		props: [
			'story',
			'row',
			'blok'
		]
	}
</script>

<style lang="scss">
	@import '~vuetify-scss/bootstrap';

	.blok-table
	{
		margin: 0;
		padding: 12px 0;

		text-align: left;

		position: relative;
		z-index: 1;

		.table
		{
			width: 100%;

			display: block;

			overflow-x: auto;

			-webkit-overflow-scrolling: touch;

			&:after
			{
				content: '';

				display: block;

				clear: both;
			}

			table
			{
				width: 100%;
				max-width: 100%;
				min-width: 500px;

				margin: 0;
				padding: 0;

				border: 1px solid #757575;
				border-collapse: collapse;

				tr
				{
					th
					{
						padding: 5px 10px;

						background-color: #d3d3d3;

						border: 1px solid #757575;

						vertical-align: top;
					}
					
					td
					{
						padding: 5px 10px;

						background-color: #ffffff;

						border: 1px solid #757575;

						vertical-align: top;
					}
				}
			}
		}

	}
</style>