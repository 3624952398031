<template>
	<div class="error-page" :style="{ 'background-color': backgroundColor }">
		<div class="logo">
			<div class="image">
				<img :src="logo|imgService({format: 'png', params: {modulate: 0, negative: true}})" :alt="siteName" />
			</div>
		</div>

		<component :is="errorPage" :error="error" />
	</div>
</template>

<script>
	import generateCss from '@/assets/js/css';

	import ErrorDefault from '~/components/error/Default';
	import Error404 from '~/components/error/404';

	export default {
		name: 'error',
		layout: 'empty',
		props: [
			'error'
		],
		components: {
			ErrorDefault: ErrorDefault,
			Error404: Error404
		},
		head()
		{
			let links = [];
			let style = [];

			if (this.$store.state.personalisation)
			{
				let fonts = {};
				if (this.$store.state.personalisation.primaryFont)
				{
					links.push
					({
						href: this.$store.state.personalisation.primaryFont,
						rel: 'stylesheet'
					});

					let result = this.$store.state.personalisation.primaryFont.match(/\?family=(.*):/);
					if (result[1])
					{
						fonts.primaryFont = result[1].replace('+', ' ') + ', sans-serif';
					}
				}

				if (this.$store.state.personalisation.secondaryFont)
				{
					links.push
					({
						href: this.$store.state.personalisation.secondaryFont,
						rel: 'stylesheet'
					});

					let result = this.$store.state.personalisation.secondaryFont.match(/\?family=(.*):/);
					if (result[1])
					{
						fonts.secondaryFont = result[1].replace('+', ' ') + ', sans-serif';
					}
				}

				style.push
				({
					cssText: generateCss(this.$store.state.personalisation, fonts),
					type: 'text/css'
				});
			}

			return {
				link: links,
				style: style
			};
		},
		computed:
		{
			logo() {
				return this.$store.state.personalisation && this.$store.state.personalisation.logo || false;
			},
			siteName() {
				return this.$store.state.settings && this.$store.state.settings.siteName || false;
			},
      backgroundColor() {
			  return this.$store.state.personalisation && this.$store.state.personalisation.primaryColor && this.$store.state.personalisation.primaryColor.color || false;
      },
			errorPage()
			{
				if (this.error.statusCode === 404) {
					return 'Error404';
				}

				return 'ErrorDefault';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.error-page
	{
		height: 100%;
		width: 100%;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		> .logo
		{
			height: auto;
			width: 20%;
			min-width: 190px;
			max-width: 300px;

			position: absolute;
			top: 20px;
			left: 50%;

			transform: translateX(-50%);

			.image
			{
				max-height: 120px;

				> img,
				> a > img
				{
					width: 100%;
				}
			}
		}
	}
</style>
