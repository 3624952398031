<template>
	<main>
		<breadcrumbs :story="story"></breadcrumbs>
		<section v-editable="blok" class="news detail">
			<v-container py-0>
				<v-layout my-0>
					<v-flex  xs12 lg10 offset-lg1 xl8 offset-xl2>
						<article class="item" :class="{'no-image': !blok.image }">

							<div v-if="blok.date" class="date">
								<span class="day">{{ blok.date|formatDate('D') }}</span>
								<span class="month">{{ blok.date|formatDate('MMM') }}</span>
								<span class="year">{{ blok.date|formatDate('YYYY') }}</span>
							</div>

							<div v-if="blok.image" class="image">
								<img :src="blok.image|resize('1600x0/smart')" :alt="blok.imageAlt" />
							</div>

							<div v-if="blok.title || blok.date || blok.text" class="content">
								<h1 v-if="blok.title">
									{{ blok.title|replaceText(replacementText) }}
								</h1>

								<s-markdown
									:tag="'div'"
									:value="blok.text|replaceText(replacementText)"
									class="text"
								/>

								<div class="button">
									<s-button-link
										:link="backUrl"
										:linkText="'Terug naar overzicht'"
									>
										{{ 'Terug naar overzicht' }}
									</s-button-link>
								</div>
							</div>

						</article>
					</v-flex>
				</v-layout>
			</v-container>
		</section>
	</main>
</template>

<script>
	import SButtonLink from '@/components/util/SButtonLink';
	import SMarkdown from '@/components/util/SMarkdown';

	import Breadcrumbs from '@/components/layout/Breadcrumbs';

	export default {
		name: 'NewsDetailPage',
		props: [
			'story',
			'blok'
		],
		components: {
			SButtonLink: SButtonLink,
			SMarkdown: SMarkdown,
			Breadcrumbs: Breadcrumbs
		},
		computed: {
			backUrl()
			{
				return this.$store.state.route.from || this.stringUrl('/nieuws');
			},
			replacementText() {
				return {
					'arnhem': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gepast Parkeren'
					}],
					'assen': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'de AsserPas'
					}],
					'gorinchem': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gastvrij Parkeren'
					}],
					'helmond': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gepast Parkeren'
					}],
					'veenendaal': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gepast Parkeren'
					}]
				}
			}
		}
	}
</script>

<style lang="scss">
	main
	{
		.news.detail
		{
			margin: 0;
			padding: 50px 0;

			background-color: #eeeeee;

			border-bottom: 1px solid #d3d3d3;

			.item
			{
				height: 100%;
				width: 100%;

				position: relative;
				z-index: 2;

				background-color: #ffffff;

				border-radius: 5px;

				overflow: hidden;

				&.no-image
				{
					> .content
					{
						padding-top: 102px;
					}
				}

				> .date
				{
					display: inline-block;

					margin: 0;
					padding: 5px 15px;

					position: absolute;
					top: 0;
					left: 30px;
					z-index: 2;

					color: #ffffff;

					> .day
					{
						display: block;

						font-size: 1.8em;
						font-weight: 400;
						line-height: 1;

						text-align: center;
					}

					> .month
					{
						display: block;

						font-size: 1em;
						font-weight: 400;
						line-height: 1.25;

						text-transform: uppercase;

						text-align: center;
					}

					> .year
					{
						display: block;

						font-size: 0.9em;
						font-weight: 400;
						line-height: 1.25;

						text-align: center;
					}
				}

				> .image
				{
					height: 0;
					width: 100%;

					margin-bottom: 20px;
					padding-top: 62.5%;
					/*padding-top: 40%;*/

					position: relative;

					overflow: hidden;

					img
					{
						width: 100%;

						position: absolute;
						top: 50%;
						left: 50%;
						z-index: 1;

						object-fit: cover;

						transform: translate(-50%, -50%);
					}
				}

				> .content
				{
					padding: 20px;

          .button
          {
            margin-top: 25px;
          }
				}
			}
		}
	}
</style>
