<template>
  <div
    v-if="!dismissed"
    class="cookie-law"
  >
    <div
      class="cookie-law-text"
    >
      <h6>Wij gebruiken cookies om je beter van dienst te zijn!</h6>
      <p>Om jou de best mogelijke ervaring op onze website te geven, maken wij gebruik van cookies. We vragen je akkoord te gaan met het plaatsen van deze cookies, zodat je zo optimaal mogelijk gebruik kunt maken van onze website en toegang hebt tot alle functionaliteiten.<p>
      <p>Wil je meer weten over ons Cookie beleid, klik dan hier voor <nuxt-link to="/cookies/">Meer informatie</nuxt-link>.</p>
    </div>
    <div
      class="cookie-law-buttons"
    >
      <div
        class="cookie-law-button"
      >
        <s-button
          @click="dismiss('false')"
        >
          Weigeren
        </s-button>
      </div>
      <div
        class="cookie-law-button"
      >
        <s-button
          @click="dismiss('true')"
        >
          Accepteren
        </s-button>
      </div>
    </div>
  </div>
</template>

<script>
  import SButton from '@/components/util/SButton'

  export default {
    name: 'CookieLaw',

    components: {
      SButton: SButton
    },

    data() {
      return {
        dismissed: true
      }
    },

    mounted() {
      this.dismissed = localStorage.getItem('cookie:accepted')
    },

    methods: {
      dismiss(value) {
        this.dismissed = true;

        localStorage.setItem('cookie:accepted', value);

        // reload page
        window.location.reload();
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~vuetify-scss/bootstrap';

  .cookie-law {
    max-width: 600px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 24px;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    background-color: #f1f1f1;

    box-shadow: 0px 10px 10px 5px rgba(0, 0, 0, 0.1);

    border-radius: 5px;

    @include breakpoint(sm-and-up) {
      bottom: 24px;
      left: 24px;
      right: auto;
    }

    .cookie-law-text {
      p {
        color: #353a47;
        font-size: 0.8rem;
      }
    }

    .cookie-law-buttons {
      width: 100%;

      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      margin-top: 16px;

      @include breakpoint(sm-and-up) {
        width: auto;

        flex: 1 0 auto;
        flex-wrap: nowrap;
        flex-direction: row;

        margin-top: 0;
        margin-left: 16px;
      }

      .cookie-law-button {
        width: 100%;

        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;

        margin: 0;
        padding: 6px;

        vertical-align: top;

        @include breakpoint(sm-and-up) {
          width: auto;
        }

        .s-button {
          width: 100%;

          @include breakpoint(sm-and-up) {
            width: auto;
          }
        }
      }
    }
  }
</style>
