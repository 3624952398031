<template>
	<button v-bind="buttonProps()" @click="$emit('click')">
		<slot />
	</button>
</template>

<script>
	export default {
		name: 'SButton',
		props: [
			'buttonType',
			'buttonDisabled',
			'buttonClass'
		],
		methods: {
			buttonProps()
			{
				return {
					type: this.buttonType || 'button',
					disabled: this.buttonDisabled || false,
					class: 'btn' + (this.buttonClass ? ' ' + this.buttonClass : '')
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.btn
	{
		margin: 0;
		padding: 10px 35px;

		display: inline-block;

		position: relative;
		z-index: 1;

		color: #ffffff;
		font-size: 1rem;
		line-height: 1.25;

		text-decoration: none;

		border: 0;
		border-radius: 50px;

    vertical-align: top;

		outline: none;

		appearance: none;

		overflow: hidden;

		&:before
		{
			content: '';

			height: 100%;
			width: 0;

			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;

			background-color: #000000;

			opacity: 0.1;

			transition: width 0.3s;
		}

		&:hover,
		&:focus
		{
			&:before
			{
				width: 100%;
			}
		}

		&.small
		{
			padding: 10px 20px;

			font-size: 0.8rem;
			line-height: 1.25;
		}

		&.has-icon
		{
			padding: 10px 45px 10px 35px;
		}

		::v-deep span
		{
			position: absolute;
			top: 50%;
			right: 20px;

			vertical-align: middle;

			transform: translateY(-50%);
		}
	}
</style>
