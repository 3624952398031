export default (req, res, next) => {

	const ip = req.headers['x-forwarded-for'] ||
		req.connection.remoteAddress ||
		req.socket.remoteAddress ||
		(req.connection.socket ? req.connection.socket.remoteAddress : null);

	const amphiaIpAddresses =
	[
		'10.1.0.170',       // Localhost
		'217.122.158.102',  // Localhost
		'83.162.204.219',   // Softmedia
		'213.127.77.159',   // Softmedia
		'84.28.53.39',      // Softmedia
		'77.172.155.12',    // Softmedia
		'5.206.212.90',     // IPParking Deurne
		'89.188.5.201',     // IPParking Zaandam
    '80.112.227.158',   // IPParking
		'85.146.210.138',   // IPParking
		'46.44.132.137',    // Stadsparkeren / IPParking
		'194.104.125.5',    // Amphia mobile devices, public network
		'194.104.125.22'    // Amphia Workplaces, fixed network
	];

	const viecuriIpAddresses =
	[
		'10.1.0.170',       // Localhost
		'217.122.158.102',  // Localhost
		'83.162.204.219',   // Softmedia
		'213.127.77.159',   // Softmedia
		'84.28.53.39',      // Softmedia
		'77.172.155.12',    // Softmedia
		'5.206.212.90',     // IPParking Deurne
		'89.188.5.201',     // IPParking Zaandam
    '80.112.227.158',   // IPParking
		'85.146.210.138',   // IPParking
    '46.44.132.137',    // Stadsparkeren / IPParking
		'194.151.80.130',   // Viecuri
		'194.151.80.131',   // Viecuri
	];

	if (
		(req.url.startsWith('/amphia-ziekenhuis') && !amphiaIpAddresses.includes(ip)) ||
		(req.url.startsWith('/viecuri-ziekenhuis') && !viecuriIpAddresses.includes(ip))
	) {
		res.writeHead(401);
		res.end();
		return;
	}

	next();
}
