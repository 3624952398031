<template>
	<section v-editable="blok" class="blok-columns">
		<v-container fluid pa-0>
			<v-layout wrap>
				<v-flex
					v-for="(block, index) of blok.blocks"
			        :key="block._uid"
			        :class="flexClass(block, index)"
				>
					<columns-block
						:blok="block"
						:side="getColumnSide(index)"
						:size="getLayoutSize(index)"
					/>
				</v-flex>
			</v-layout>
		</v-container>
	</section>
</template>

<script>
	import ColumnsBlock from '@/components/bloks/columns/ColumnsBlock'

	export default {
		name: 'Columns',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			ColumnsBlock: ColumnsBlock
		},
		methods: {
			flexClass(block, index) {

				const side = this.getColumnSide(index);
				const size = this.getLayoutSize(index);

				if (block.image && block.imageStyle === 'fullwidth') {
					return `xs12 md${size}`;
				} else if (side === 'left') {
					return `xs12 md${size - 1} offset-md1`;
				} else {
					return `xs12 md${size - 1}`;
				}
			},
			getColumnSide(index) {
				const sides = [
					'left',
					'right'
				];

				return sides[index];
			},
			getLayoutSize(index) {
				return this.blok.layout.split(';')[index] || '6';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.blok-columns
	{
		height: 100%;
		width: 100%;

		background-color: #ffffff;

		border-bottom: 1px solid #d3d3d3;
	}
</style>