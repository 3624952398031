export default ({ app, store, route }, inject) => {
  if (!localStorage.getItem('cookie:accepted')) {
    return;
  }

  if (localStorage.getItem('cookie:accepted') !== 'true') {
    return;
  }

  const id = store.state?.settings?.googleAnalyticsId || false;
  if (!id) {
    return;
  }

  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  script.async = true;

  document.querySelector('head').appendChild(script);

  script.addEventListener('load', () => {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    inject('gtag', gtag);

    // first page view
    gtag('js', new Date())
    gtag('config', id, JSON.stringify({ anonymize_ip: true }, null, 2));
    gtag('config', id, { 'page_path': route.fullPath, 'location_path': window.location.origin + route.fullPath});

    // all other page views
    app.router.afterEach((to) => {
      gtag('config', id, { 'page_path': to.fullPath, 'location_path': window.location.origin + to.fullPath });
    })
  });
};
