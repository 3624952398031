export const state = () => ({
	cacheVersion: '',
	version: '',
	editMode: false,
	links: null,
	settings: {},
	personalisation: {},
  mobileNavOpen: false
});

export const mutations = {
	setCacheVersion (state, version) {
		state.cacheVersion = version
	},
	setVersion (state, version) {
		state.version = version
	},
	setEditMode(state, editMode) {
		state.editMode = editMode;
	},
	setLinks(state, links) {
		state.links = links;
	},
	setSettings(state, settings) {
		state.settings = settings;
	},
	setPersonalisation(state, personalisation) {
		state.personalisation = personalisation;
	},
  setMobileNavOpen(state, mobileNavOpen) {
    state.mobileNavOpen = mobileNavOpen;
  },
};

export const actions = {
	loadCacheVersion({ commit }) {
		return this.$storyapi.get(`cdn/spaces/me`).then((res) => {
			commit('setCacheVersion', res.data.space.version)
		});
	},
	loadLinks({ commit, state }) {
		return this.$storyapi.get('cdn/links', {
			version: state.version
		}).then((res) => {
			commit('setLinks', res.data.links);
		});
	},
	loadSettings({ commit, state }) {
		return this.$storyapi.get(`cdn/stories${state.config.root}/instellingen`, {
			version: state.version
		}).then((res) => {
			commit('setSettings', res.data.story.content)
		}).catch(error => {
			// eslint-disable-next-line
			console.error('Missing `settings` page');
			
			throw error;
		});
	},
	loadPersonalisation({ commit, state }) {
		return this.$storyapi.get(`cdn/stories${state.config.root}/personalisatie`, {
			version: state.version
		}).then((res) => {
			commit('setPersonalisation', res.data.story.content)
		}).catch(error => {
			// eslint-disable-next-line
			console.error('Missing `personalisation` page');
			
			throw error;
		});
	}
};
