<template>
	<div class="pricing-option-property">
		<span>{{ option.key }}</span>
		<span>{{ option.value }}</span>
	</div>
</template>

<script>
	export default {
		name: 'PricingOptionProperty',
		props: [
			'block',
			'option',
			'bgColor'
		]
	}
</script>

<style lang="scss" scoped>
	.pricing-option-property
	{
		margin: 0;
		padding: 10px 15px;

		display: flex;
		flex: 1 1 auto;
		flex-direction: row;

		text-align: left;

		span
		{
			max-width: 50%;

			flex: 1 1 auto;
			flex-basis: 50%;
			flex-grow: 0;

			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;

			&:first-child
			{
				text-align: left;
			}

			&:last-child
			{
				text-align: right;
			}
		}
	}
</style>
