import MarkdownIt from 'markdown-it'

const handlePlugin = (plugin) => plugin.default || plugin

export default ({ app }, inject) => {
  const md = new MarkdownIt('default', {"breaks":true,"linkify":true})

  md.use(handlePlugin(require('markdown-it-link-attributes')), {"pattern":new RegExp("^https?:", ""),"attrs":{"target":"_blank","rel":"noopener"}} )

  inject('md', md)
}
