<template>
	<v-app class="wrapper" :class="{'clean': _viewClean}">
		<BreakdownDialog />
		<Header />
		<nuxt />
		<Footer />
    <CookieLaw />
	</v-app>
</template>

<script>
	import lazySizes from 'lazysizes';
	import 'lazysizes/plugins/attrchange/ls.attrchange';

  import { mapState } from 'vuex';

	import BreakdownDialog from '@/components/dialog/BreakdownDialog';

	import Header from '~/components/layout/Header';
	import Footer from '~/components/layout/Footer';
  import CookieLaw from '~/components/layout/CookieLaw';

	export default {
		name: 'default',
		components: {
			BreakdownDialog: BreakdownDialog,
			Header: Header,
			Footer: Footer,
      CookieLaw: CookieLaw
		},
    data() {
		  return {
		    scrollY: 0
      }
    },
    watch: {
		  mobileNavOpen(newValue, oldvalue) {
		    const body = document.body;

		    if (newValue) {
          body.style.position = 'fixed';
          body.style.top = `-${this.scrollY}px`;
        } else {
          body.style.position = '';
          body.style.top = '';

          this.$scrollTo('body', 1, {
            offset: this.scrollY
          });
        }
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    computed: {
      ...mapState(['mobileNavOpen']),

      _viewClean() {
		    return this.$route.query.view && this.$route.query.view === 'clean';
      }
    },
    methods: {
		  handleScroll() {
		    if (!this.mobileNavOpen) {
          this.scrollY = window.scrollY;
        }
      }
    }
  }
</script>
