<template>
	<section v-if="blok.blocks.length" class="header-large">
		<div v-swiper:mySwiper="swiperOptions">
			<div class="swiper-wrapper">
				<div v-editable="block" v-for="(block, index) in blok.blocks" :key="index" class="swiper-slide">

					<div class="swiper-item">

						<div v-if="block.image" :data-background="block.image|resize('2000x0')" class="image swiper-lazy"></div>

						<div v-if="block.title || block.text || is_link(block.link)" class="content">
							<v-container fill-height py-0>
								<v-layout align-center>
									<v-flex xs12 xl10 offset-xl1>
                    <component v-if="block.title" :is="block.heading">
                      {{ block.title|replaceText }}
                    </component>

										<p v-if="block.text" class="text">{{ block.text|replaceText }}</p>

										<div class="button">
											<s-button-link
												:link="block.link"
												:linkText="block.linkText || 'Lees meer'"
											>
												{{ block.linkText || 'Lees meer' }}
											</s-button-link>
										</div>
									</v-flex>
								</v-layout>
							</v-container>
						</div>

					</div>

				</div>
			</div>

			<div class="swiper-navigation">
				<div class="swiper-buttons">
					<div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
					<div class="swiper-button-next swiper-button-white" slot="button-next"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import SButtonLink from '@/components/util/SButtonLink'

	export default {
		name: 'HeaderLarge',
		props: [
			'blok'
		],
		components: {
			SButtonLink: SButtonLink
		},
		data () {
			return {
				swiperOptions: {
					speed: 1000,
					loop: true,
					loopedSlides: this.blok.blocks.length,
					lazy: {
						loadPrevNext: true,
						loadOnTransitionStart: true
					},
					preloadImages: false,
					effect: 'fade',
					fadeEffect: {
						crossFade: true
					},
					slidesPerView: 'auto',
					centeredSlides: true,
					spaceBetween: 0,
					autoplay: {
						delay: 5000,
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				}
			}
		},
		mounted() {
			if (this.blok.blocks.length <= 1 && this.mySwiper) {
				this.mySwiper.autoplay.stop();
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '~vuetify-scss/bootstrap';

	.header-large
	{
		margin: 0;
		padding-top: 460px;

		position: relative;
		z-index: 1;

		.swiper-container
		{
			height: 100%;
			width: 100%;

			position: absolute;
			top: 0;
			left: 0;

			.swiper-wrapper
			{
				height: 100%;
				width: 100%;

				.swiper-slide
				{
					height: 100%;

					.swiper-item
					{
						height: 100%;

						display: flex;
						flex-direction: row;

						overflow: hidden;

						box-sizing: border-box;

						.image
						{
							max-width: 100%;

							flex: 0 0 100%;

							position: relative;
							z-index: 2;

							background-repeat: no-repeat;
							background-size: cover;
							background-position: center;

							&:after
							{
								content: '';

								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;

								background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 100%, rgba(0, 0, 0, 0.1) 100%);
							}
						}

						.content
						{
							max-width: 100%;

							flex: 0 0 100%;

							margin: 0;
							padding: 50px 0;

							position: relative;
							z-index: 2;

							transform: translateX(-100%);

							h1,
              h2,
              h3,
              h4,
              h5,
              h6
							{
								margin: 0;
								padding: 0;

								color: #ffffff;
								font-size: 2.4rem;
								font-weight: 700;
								line-height: 1.25;
							}

							.text
							{
								color: #ffffff;
								font-size: 1rem;
								font-weight: 400;
								line-height: 1.5;

								white-space: pre-line;
							}
						}
					}

				}
			}

			.swiper-navigation
			{
				height: 100%;

				display: none;

				> .swiper-buttons
				{
					height: 100%;

					> .swiper-button-prev,
					> .swiper-button-next
					{
						height: 100%;
						width: 50px;

						top: 0;

						opacity: 0;

						outline: none;

						cursor: pointer;

						transition: opacity 0.5s;
					}

					> .swiper-button-prev
					{
						left: 0;
					}

					> .swiper-button-next
					{
						right: 0;
					}
				}
			}

			&:hover
			{
				.swiper-navigation > .swiper-buttons > .swiper-button-prev,
				.swiper-navigation > .swiper-buttons > .swiper-button-next
				{
					opacity: 1;
				}
			}
		}
	}

	@include breakpoint(sm-and-up)
	{
		section.header-large
		{
			.swiper-container .swiper-wrapper .swiper-slide .swiper-item .content
			{
        h1,
        h2,
        h3,
        h4,
        h5,
        h6
				{
					font-size: 2.8rem;
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		section.header-large
		{
			padding-top: 50%;

			.swiper-container .swiper-wrapper .swiper-slide .swiper-item .content
			{
        h1,
        h2,
        h3,
        h4,
        h5,
        h6
				{
					font-size: 3.2rem;
				}

				.text
				{
					font-size: 1.1rem;
				}
			}
		}
	}

	@include breakpoint(lg-and-up)
	{
		section.header-large
		{
			padding-top: 680px;

			.swiper-container .swiper-wrapper .swiper-slide .swiper-item .content
			{
        h1,
        h2,
        h3,
        h4,
        h5,
        h6
				{
					font-size: 3.6rem;
				}

				.text
				{
					font-size: 1.2rem;
				}
			}

			.swiper-container .swiper-navigation
			{
				display: block;
			}
		}
	}
</style>
