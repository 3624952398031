<template>
	<section v-editable="blok" v-if="blok.text" :class="blockClass">
		<div class="indent" :style="indentStyle"></div>
		<div class="content">
			<s-markdown
				:tag="'div'"
				:value="blok.text"
				class="text"
			/>
		</div>
	</section>
</template>

<script>
	import SMarkdown from '@/components/util/SMarkdown';

	export default {
		name: 'Text',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SMarkdown: SMarkdown
		},
		computed: {
			blockClass() {
				return `blok-text align-text-${this.blok.align}`;
			},
			indentStyle() {
				if (this.blok.backgroundColor === 'primary') {
					return {
						backgroundColor: this.$store.state.personalisation.primaryColor && this.$store.state.personalisation.primaryColor.color
					};
				} else if (this.blok.backgroundColor === 'secondary') {
					return {
						backgroundColor: this.$store.state.personalisation.secondaryColor && this.$store.state.personalisation.secondaryColor.color
					};
				}

				return null;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '~vuetify-scss/bootstrap';

	.blok-text
	{
		margin: 0;
		padding: 12px 0;

		text-align: left;

		position: relative;
		z-index: 1;

		&.align-text-center
		{
			text-align: center;
		}

		&.align-text-justify
		{
			text-align: justify;
		}

		.indent
		{
			height: 100%;
			width: calc(100% + 52px);

			display: none;

			position: absolute;
			top: 0;
			left: -26px;

			border-radius: 5px;
		}

		.content
		{
			.text
			{
				position: relative;
				z-index: 2;

				::v-deep
				{
					h1,
					h2,
					h3,
					h4,
					h5,
					h6
					{
						display: inline-block;

						position: relative;

						&:before
						{
							content: '';

							height: 1px;

							margin: 0;

							position: absolute;
							left: 0;
							right: 0;
							bottom: -12.5px;

							background-color: #d3d3d3;
						}
					}
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		.blok-text
		{
			&.first
			{
				margin-top: -100px;
				padding-top: 26px;

				.indent
				{
					display: block;
				}
			}

			&.align-text-center
			{
				.content .text
				{
					::v-deep h1:before
					{
						left: -20px;
						right: -20px;
					}
				}
			}
		}
	}
</style>