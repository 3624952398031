export default () => {
  if (!localStorage.getItem('cookie:accepted')) {
    return;
  }

  if (localStorage.getItem('cookie:accepted') !== 'true') {
    return;
  }

  window.Tawk_API = {};
  window.Tawk_LoadStart = new Date();

  let s1=document.createElement("script"),
  s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/5cbf265dc1fe2560f30029ca/default';
  s1.charset='UTF-8';
  s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
};
