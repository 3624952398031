<template>
	<section class="form-register">
		<v-layout justify-center wrap>
			<v-flex xs12 lg8>
        <div class="form-register-full">
          <div class="form-register-full-options">
            <v-layout justify-center wrap my-0>
              <v-flex v-for="(option, index) in options" :key="index" xs12 sm4>
                <div class="option" @click="click(option.link)">
                  <span :class="option.icon"></span>
                  {{ option.title }}
                </div>
              </v-flex>
            </v-layout>
          </div>
        </div>
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
	export default {
		name: 'FormRegister',
		props: [
			'story',
			'row',
			'blok'
		],
    data() {
      return {
        options: [{
          icon: 'icon-card',
          name: 'card',
          title: 'Ja, ik heb een pas',
          link: this.blok.linkCard || null
        }, {
          icon: 'icon-no-card',
          name: 'no-card',
          title: 'Nee, ik heb geen pas',
          link: this.blok.linkNoCard || null
        }, {
          icon: 'icon-ov-card',
          name: 'ov-card',
          title: 'Ik heb een OV-chipkaart',
          link: this.blok.linkOvCard || null
        }],
      }
    },
    methods: {
      click(link) {
        if (!this.is_link(link)) {
          return;

        }

        const url = this.url(link);

        if (url.includes('://')) {
          window.open(url, '_blank');
        } else {
          this.$router.push(url);
        }
      }
    }
	}
</script>

<style lang="scss">
	.form-register
	{
		margin: 0;
		padding: 12px 0;

		.form-register-full
		{
			.form-register-full-options
			{
				.option
				{
					height: 100%;
					width: 100%;

          display: block;

          margin: 0;
          padding: 40px 10px;

          font-size: 1rem;
          font-weight: 700;
          line-height: 1.5;
          text-align: center;

          background-color: #ffffff;

          border: 1px solid #999999;
          border-radius: 5px;

          cursor: pointer;

          span[class^="icon-"],
          span[class*=" icon-"]
          {
            display: block;

            margin: 0;
            padding-bottom: 20px;

            font-size: 2.5rem;
          }
				}
			}
		}

		.v-subheader
		{
			height: 38px;

			padding-left: 0;
			padding-right: 0;
		}

		.v-input--selection-controls
		{
			margin-top: 0;

			.v-input__slot
			{
				align-items: flex-start;

				.v-label
				{
					color: #2c2c2c;
					line-height: 24px;
				}
			}

		}

		.v-text-field
		{
			.v-input__control .v-input__slot
			{
				background-color: #ffffff !important;
			}
		}
	}
</style>
