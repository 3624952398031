<template>
	<div class="nav" :class="{mobile: isMobile, desktop: !isMobile, open: isOpen, scrolled: isScrolled}">
		<v-container fluid py-0>
			<v-layout wrap my-0>

				<v-flex xs3 lg1>
					<div v-if="logoWhite" class="logo">
						<nuxt-link to="/" :title="siteName">
							<img :src="logoWhite" :alt="siteName" />
						</nuxt-link>
					</div>
				</v-flex>

				<v-flex xs9 lg11 hidden-lg-and-up>
					<div class="main-nav-close-btn" @click="toggleMobileNav()">
						<span></span>
					</div>
					<div class="main-nav-btn" @click="toggleMobileNav()">
						<span></span>
					</div>
				</v-flex>

				<v-flex xs12 lg11>
					<nav class="main-nav">
						<ul>
							<s-link-wrapper
								v-for="item in items"
								:key="item._uid"
								:tag="'li'"
								:link="item.link"
								:linkText="item.name"
								:linkClass="{home: isHome(item.link), highlight: item.highlight}"
								@click.native="closeMobileNav()"
							>
								{{ item.name }}
							</s-link-wrapper>

<!--							<li class="hidden-lg-and-up empty">&nbsp;</li>-->

							<s-link-wrapper
								v-for="item in quickItems.slice().reverse()"
								:key="item._uid"
								:tag="'li'"
								:link="item.link"
								:linkText="item.name"
								class="hidden-lg-and-up has-icon"
								@click.native="closeMobileNav()"
							>
								<span
									v-if="item.icon && item.icon.selected"
									class="material-icons"
								>
									{{ item.icon.selected }}
								</span>
								{{ item.name }}
							</s-link-wrapper>
              <li v-if="breakdowns.length" class="hidden-lg-and-up has-icon">
                <nuxt-link :to="stringUrl('/storingen')" title="Mededelingen" @click.native="closeMobileNav()">
                  <span class="material-icons">warning</span>
                  Mededelingen
                </nuxt-link>
              </li>

              <li class="hidden-lg-and-up">
                <a href="https://itunes.apple.com/us/app/stadsparkeren/id892308309?ls=1&mt=8" target="_blank" title="Stadsparkeren iPhone app" class="icon">
                  <span class="icon-apple" title="Stadsparkeren iPhone app"></span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=nl.stadsparkeren" target="_blank" title="Stadsparkeren Android app" class="icon">
                  <span class="icon-android" title="Stadsparkeren Android app"></span>
                </a>
              </li>
						</ul>
					</nav>
				</v-flex>

			</v-layout>
		</v-container>
	</div>
</template>

<script>
	import SLinkWrapper from '@/components/util/SLinkWrapper';

	export default {
		name: 'HeaderNav',
		props: [
			'breakdowns'
		],
		components: {
			SLinkWrapper: SLinkWrapper
		},
		data() {
			return {
				isMobile: true,
				isOpen: false,
				isScrolled: false
			}
		},
		mounted() {
			this.handleResize();
			this.handleScroll();
		},
		created()
		{
			if (process.browser) {
				window.addEventListener('resize', this.handleResize);
				window.addEventListener('scroll', this.handleScroll);
			}
		},
		destroyed()
		{
			if (process.browser) {
				window.removeEventListener('resize', this.handleResize);
				window.removeEventListener('scroll', this.handleScroll);
			}
		},
		computed: {
			logoWhite() {
				return this.$store.state.settings && this.$store.state.personalisation.logoWhite || false;
			},
			siteName() {
				return this.$store.state.settings && this.$store.state.settings.siteName || false;
			},
			items() {
				return this.$store.state.settings && this.$store.state.settings.mainNav || [];
			},
			quickItems() {
				return this.$store.state.settings && this.$store.state.settings.quickNav || [];
			}
		},
		methods: {
			isHome(link) {
				return link && link.cached_url.match(/^[^\/]+\/$/g);
			},
			handleResize()
			{
				if (window.innerWidth > 1263)
				{
					this.isMobile = false;
					this.isOpen = false;
				}
				else
				{
					this.isMobile = true;
				}
			},
			handleScroll()
			{
			  if (!this.isOpen) {
          if (window.pageYOffset > 120)
          {
            this.isScrolled = true;
          }
          else
          {
            this.isScrolled = false;
          }
        }
			},
			toggleMobileNav()
			{
				this.isOpen = !this.isOpen;

				this.$store.commit('setMobileNavOpen', this.isOpen);
			},
			closeMobileNav()
			{
				this.isOpen = false;

        this.$store.commit('setMobileNavOpen', false);
			}
		}
	}
</script>

<style lang="scss">
	@import '~vuetify-scss/bootstrap';

	.nav
	{
		height: 60px;
		width: 100%;

		position: relative;

		&:before
		{
			content: '';

			height: 200%;
			width: 100%;

			position: fixed;
			top: 0;
			right: -100%;
			z-index: 101;

			background-color: #000000;

			opacity: 0;

      overflow: hidden;

			transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
		}

		.logo
		{
			height: 60px;

			margin: 0;
			padding: 10px 0;

			visibility: hidden;
			opacity: 0;

			transition: visibility 0.2s cubic-bezier(0.4, 0, 0.2, 1),
						opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);

			> img,
			> a > img
			{
        max-height: 100%;
				max-width: 100%;

        vertical-align: middle;
			}
		}

		.main-nav-close-btn
		{
			height: 40px;
			width: 40px;

			margin: 10px 10px 0 0;
			padding: 0;

			position: fixed;
			top: 0;
			right: -350px;
			z-index: 103;

			border-radius: 3px;

			transition: right 0.2s cubic-bezier(0.4, 0, 0.2, 1);

			&:before,
			&:after
			{
				content: '';

				height: 4px;
				width: 30px;

				display: block;

				position: absolute;
				top: 50%;
				right: 5px;

				background-color: #ffffff;

				border-radius: 3px;
			}

			&:before
			{
				transform: translateY(-50%) rotate(45deg);
			}

			&:after
			{
				transform: translateY(-50%) rotate(-45deg);
			}
		}

		.main-nav-btn
		{
			height: 60px;
			width: 100%;

			display: block;

			margin: 0;
			padding: 0;

			position: relative;

			&:before,
			&:after,
			> span
			{
				content: '';

				height: 4px;
				width: 45px;

				display: block;

				position: absolute;
				top: 28px;
				right: 0;

				background-color: #ffffff;

				border-radius: 3px;
			}

			&:before
			{
				width: 40px;

				top: 16px;
			}

			&:after
			{
				width: 35px;

				top: 40px;
			}
		}

		&.mobile
		{
			.main-nav
			{
				height: 100%;
				width: 300px;
				max-width: 90%;

				display: block;

				margin: 0;
				padding: 80px 0 20px 0;

				position: fixed;
				top: 0;
				right: -350px;
				z-index: 102;

				overflow-y: auto;
        overflow-x: hidden;

				transition: right 0.2s cubic-bezier(0.4, 0, 0.2, 1);

				-webkit-box-shadow: 0px 0px 20px 20px rgba(0,0,0,0.2);
				-moz-box-shadow: 0px 0px 20px 20px rgba(0,0,0,0.2);
				box-shadow: 0px 0px 20px 20px rgba(0,0,0,0.2);

        -webkit-overflow-scrolling: touch;

				> ul
				{
					display: block;

					margin: 0;
					padding: 0;

					list-style: none;

					> li
					{
						display: block;

						margin-top: -1px;

            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

						> a
						{
							height: 100%;
							width: 100%;

							display: block;

							margin: 0;
							padding: 15px 20px;

							position: relative;

							color: #ffffff;
							font-size: 0.9rem;
							font-weight: 400;
							line-height: 1.75;

              border-left: 2px solid transparent;

							text-decoration: none;

              &.icon
              {
                width: auto;

                display: inline-block;
              }

							&:hover,
							&:focus,
							&.nuxt-link-active:not(.home),
							&.nuxt-link-exact-active
							{
								background-color: rgba(255, 255, 255, 0.2);

								border-left: 2px solid #ffffff;
							}
						}

						&.empty
						{
							padding: 15px 20px;
						}

						&.has-icon
						{
							> a
							{
								padding-left: 50px;

								span.material-icons
								{
									position: absolute;
									top: 50%;
									left: 20px;

									color: rgba(255, 255, 255, 0.8);
									font-size: 1.1rem;

									transform: translateY(-50%);
								}
							}
						}
					}
				}
			}

			&.open
			{
				&:before
				{
					right: 0;

					opacity: 0.3;
				}

				.main-nav-close-btn,
				.main-nav
				{
					right: 0;
				}
			}
		}

		&.desktop
		{
			.main-nav
			{
				display: block;

				margin: 0;
				padding: 0;

				position: relative;

				text-align: right;

				ul
				{
					display: inline-block;

					margin: 0;
					padding: 0;

					list-style: none;

					> li
					{
						display: inline-block;

						> a
						{
							display: block;

							margin: 0;
							padding: 16px;

							position: relative;

							color: #ffffff;
							font-size: 0.8rem;
							font-weight: 400;
							line-height: 1.75;

							text-decoration: none;

							&:before
							{
								content: '';

								height: 100%;
								width: 0;

								position: absolute;
								top: 0;
								left: 50%;

								background-color: rgba(255, 255, 255, 0.05);

								transition: width 0.2s, left 0.2s;
							}

							&:after
							{
								content: '';

								height: 2px;
								width: 0;

								position: absolute;
								left: 50%;
								bottom: 0;

								background-color: #ffffff;

								transition: width 0.3s, left 0.3s;
							}

							&:hover,
							&:focus,
							&.nuxt-link-active:not(.home),
							&.nuxt-link-exact-active
							{
								&:before,
								&:after
								{
									width: 100%;

									left: 0;
								}
							}

							&.highlight
							{
								//margin: 0 10px;
								padding: 8px 20px;

								color: #ffffff;
								font-size: 0.8rem;
								font-weight: 700;
								line-height: 1.75;

								border-radius: 3px;

								&:before,
								&:after
								{
									content: none;
								}

								&.nuxt-link-exact-active
								{
									background-color: darken(#4fb127, 10%);
								}
							}
						}
					}
				}
			}
		}

		&.scrolled
		{
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1001;

			.logo
			{
				visibility: visible;
				opacity: 1;
			}
		}
	}
</style>
