<template>
	<section v-if="blok.blocks.length" class="header-small">
		<div v-swiper:mySwiper="swiperOptions">
			<div class="swiper-wrapper">
				<div v-editable="block" v-for="(block, index) in blok.blocks" :key="index" class="swiper-slide">
					<div class="swiper-item">
						<div v-if="block.image" :data-background="block.image|resize('2000x0')" class="image swiper-lazy"></div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'HeaderSmall',
		props: [
			'blok'
		],
		data () {
			return {
				swiperOptions: {
					speed: 1000,
					loop: true,
					loopedSlides: this.blok.blocks.length,
					lazy: {
						loadPrevNext: true,
						loadOnTransitionStart: true
					},
					preloadImages: false,
					effect: 'fade',
					fadeEffect: {
						crossFade: true
					},
					slidesPerView: 'auto',
					centeredSlides: true,
					spaceBetween: 0,
					autoplay: {
						delay: 5000,
					}
				}
			}
		},
		mounted() {
			if (this.blok.blocks.length <= 1 && this.mySwiper) {
				this.mySwiper.autoplay.stop();
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '~vuetify-scss/bootstrap';

	.header-small
	{
		margin: 0;
		padding-top: 280px;

		position: relative;
		z-index: 1;

		.swiper-container
		{
			height: 100%;
			width: 100%;

			position: absolute;
			top: 0;
			left: 0;

			.swiper-wrapper
			{
				height: 100%;
				width: 100%;

				&:before
				{
					content: '';

					height: 100%;
					width: 100%;

					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1001;

					backface-visibility: hidden;
				}

				.swiper-slide
				{
					height: 100%;

					.swiper-item
					{
						height: 100%;

						display: flex;
						flex-direction: row;

						overflow: hidden;

						box-sizing: border-box;

						.image
						{
							max-width: 100%;

							flex: 0 0 100%;

							position: relative;
							z-index: 2;

							background-repeat: no-repeat;
							background-size: cover;
							background-position: center;

							&:after
							{
								content: '';

								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;

								background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 100%, rgba(0, 0, 0, 0.1) 100%);
							}
						}
					}
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		section.header-small
		{
			padding-top: 30%;
		}
	}

	@include breakpoint(lg-and-up)
	{
		section.header-small
		{
			padding-top: 400px;
		}
	}
</style>