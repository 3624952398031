<template>
	<section v-editable="blok" v-if="blok.videoFile || blok.youtube || blok.vimeo" class="blok-video">
		<div class="indent" :style="indentStyle"></div>
		<v-layout wrap my-0>
			<v-flex :class="flexClass">
				<div class="video">
					<div class="inner">
						<video v-if="blok.videoFile" controls>
							<source :src="blok.videoFile" type="video/mp4">
							Je browser ondersteund dit niet.
						</video>

						<iframe
							v-else-if="blok.youtube"
							:src="`//www.youtube.com/embed/${blok.youtube}`"
							allowfullscreen
							allow="fullscreen"
							frameborder="0"
						></iframe>

						<iframe
							v-else-if="blok.vimeo"
							:src="`//player.vimeo.com/video/${blok.vimeo}`"
							allowfullscreen
							allow="fullscreen"
							frameborder="0"
						></iframe>
					</div>
				</div>
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
	export default {
		name: 'Video',
		props: [
			'story',
			'row',
			'blok'
		],
		computed: {
			flexClass() {
				let className = 'xs12 md8 offset-md2';
				if (this.blok.videoAlign === 'left') {
					className = 'xs12 md8';
				} else if (this.blok.videoAlign === 'right') {
					className = 'xs12 md8 offset-md4';
				}

				return this.blok.videoStyle === 'fullwidth' ? 'xs12' : className;
			},
			indentStyle() {
				if (this.blok.backgroundColor === 'primary') {
					return {
						backgroundColor: this.$store.state.personalisation.primaryColor && this.$store.state.personalisation.primaryColor.color
					};
				} else if (this.blok.backgroundColor === 'secondary') {
					return {
						backgroundColor: this.$store.state.personalisation.secondaryColor && this.$store.state.personalisation.secondaryColor.color
					};
				}

				return null;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '~vuetify-scss/bootstrap';

	.blok-video
	{
		margin: 0;
		padding: 12px 0;

		text-align: left;

		position: relative;
		z-index: 1;

		.indent
		{
			height: 100%;
			width: calc(100% + 52px);

			display: none;

			position: absolute;
			top: 0;
			left: -26px;

			border-radius: 5px;
		}

		.video
		{
			.inner
			{
				height: 0;

				position: relative;

				padding-bottom: 56.25%;

				> video,
				> iframe
				{
					width: 100%;

					display: block;

					position: absolute;
					top: 0;
					left: 0;
				}

				> iframe
				{
					height: 100%;
				}

				> video
				{
					margin: 0;
					padding: 0;

					border: 1px solid #d3d3d3;
				}
			}

		}
	}

	@include breakpoint(md-and-up)
	{
		.blok-video
		{
			&.first
			{
				margin-top: -100px;
				padding-top: 26px;

				.indent
				{
					display: block;
				}
			}
		}
	}
</style>