<template>
	<section class="form-contact">
		<v-layout justify-center wrap>
			<v-flex xs12 lg8>
				<div class="form">
					<v-form @submit.prevent="submit" ref="form" lazy-validation novalidate>
						<div class="form-fields">
							<v-text-field
								v-model="fields.name"
								label="Naam *"
								:rules="rules.name"
								validate-on-blur
								required
								outlined
							></v-text-field>

              <v-text-field
                v-model="fields.email"
                label="Email *"
                :rules="rules.email"
                validate-on-blur
                required
                outlined
              ></v-text-field>

              <v-text-field
								v-model="fields.telephone"
								label="Telefoon"
								outlined
              ></v-text-field>

              <v-text-field
                v-model="fields.identification"
                label="Pasnummer/kenteken"
                outlined
              ></v-text-field>

							<v-file-input
								v-model="fields.file"
								label="Bestand"
								prependIcon=""
								appendIcon="mdi-paperclip"
								outlined
							/>

							<v-textarea
								v-model="fields.message"
								label="Vraag/opmerking"
								outlined
							></v-textarea>

              <v-checkbox
                v-model="fields.privacy"
                :rules="rules.privacy"
                required
              >
                <template v-slot:label>
                  <div>
                    Ik geef toestemming voor het verwerken van de ingevulde gegevens. Lees hier meer over in onze
                    <a
                      target="_blank"
                      href="https://stadsparkeren.nl/privacyverklaring"
                      @click.stop
                    >
                      privacyverklaring.
                    </a>
                    *
                  </div>
                </template>
              </v-checkbox>

							<div class="recaptcha">
								<p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
							</div>

							<div class="form-buttons">
								<p>
									<s-button
                    id="contact"
										:buttonType="'submit'"
										:buttonDisabled="submitting"
									>
										Versturen
									</s-button>
								</p>
							</div>
						</div>
					</v-form>
				</div>
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
	import SButton from '@/components/util/SButton'

	export default {
		name: 'FormContact',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SButton: SButton
		},
		data() {
			return {
				fields: {
					page: typeof window !== 'undefined' ? window.location.href : '',
					name: '',
          email: '',
          telephone: '',
          identification: '',
					file: [],
					message: '',
          privacy: ''
				},

				rules: {
					name: [
						v => !!v || 'Vul een naam in'
					],
					email: [
						v => !!v || 'Vul een e-mailadres in',
						v => /.+@.+/.test(v) || 'Vul een geldig e-mailadres in'
					],
          privacy: [
            v => !!v || 'U dient akkoord te gaan met het verwerken van uw gegevens'
          ]
				},

				recaptcha: null,

				submitting: false
			}
		},
		async mounted()
		{
			this.recaptcha = await this.$recaptcha();
		},
		methods: {
			async submit ()
			{
				if (!this.$refs.form.validate()) {
					return;
				}

				this.submitting = true;

				let token;
				try {
					token = this.recaptcha && await this.recaptcha.execute('contact');
				}
				catch (e) {
					throw `Recaptcha failed ${(e || '').toString()}`
				}

				try
				{
					const values = {};
					const attachments = [];

					for (let key of Object.keys(this.fields)) {
						let value = this.fields[key];

						if (value instanceof File) {
							attachments.push({
								name: value.name,
								data: await this.getBase64(value)
							});

							values[key] = attachments.map(attachment => attachment.name);
						} else {
							values[key] = this.fields[key];
						}
					}

          const data = {
						values: values,
						attachments: attachments,
						options: {
							story: this.story.uuid,
							blok: this.blok._uid
						},
						...token ? {
							token
						} : {}
          };

					await this.$axios.$post('/api/contact', data);

					this.submitting = false;

					if (this.is_link(this.blok.page)) {
						const url = this.url(this.blok.page);

						if (url.includes('://')) {
							return location.href = url;
						}

						return await this.$router.push(url);
					}
				}
				catch (e)
				{
					console.error(e.response ? e.response.data : e);

					this.submitting = false;
				}
      },
			async getBase64(file) {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
						if ((encoded.length % 4) > 0) {
							encoded += '='.repeat(4 - (encoded.length % 4));
						}
						resolve(encoded);
					};
					reader.onerror = error => reject(error);
				});
			}
		}
	}
</script>

<style lang="scss">
	.form-contact
	{
		margin: 0;
		padding: 12px 0;

		.recaptcha p
		{
			font-size: 0.8rem;
		}

    .v-input--checkbox {
      margin: 0 16px 8px 0;
      padding: 0;

      .v-input__slot {
        margin: 0 0 8px 0;
      }
    }

		.v-text-field .v-input__control .v-input__slot {
      background-color: #ffffff !important;
    }

    // fix for indent on (error) message when using filled or outlined
    .v-messages {
      margin-bottom: 16px;

      .v-messages__message {
        font-weight: 400;
      }
    }

    // fix for indent on (error) message when using filled or outlined
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      margin: 0;
      padding: 0;
    }
	}
</style>
