<template>
	<s-link-type v-bind="linkProps()">
		<slot />
	</s-link-type>
</template>

<script>
	import SLinkType from '@/components/util/SLinkType';

	export default {
		name: 'SButtonLink',
		components: {
			SLinkType: SLinkType
		},
		props: [
			'link',
			'linkText',
			'linkClass',
			'linkStyle'
		],
		methods: {
			linkProps()
			{
				return {
					link: this.link || false,
					linkText: this.linkText || false,
					linkStyle: this.linkStyle || false,
					linkClass: 'btn' + (this.linkClass ? ' ' + this.linkClass : '')
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.btn
	{
		margin: 0;
		padding: 10px 35px;

		display: inline-block;

		position: relative;
		z-index: 1;

		color: #ffffff;
		font-size: 1rem;
		line-height: 1.25;

		text-decoration: none;

		border: 0;
		border-radius: 50px;

    vertical-align: top;

		appearance: none;

		overflow: hidden;

		&:before
		{
			content: '';

			height: 100%;
			width: 0;

			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;

			background-color: #000000;

			opacity: 0.05;

			transition: width 0.3s;
		}

		&:hover,
		&:focus
		{
			&:before
			{
				width: 100%;
			}
		}

		&.small
		{
			padding: 10px 20px;

			font-size: 0.8rem;
			line-height: 1.25;
		}

		&.has-icon
		{
			padding: 10px 45px 10px 35px;
		}

		::v-deep span
		{
			position: absolute;
			top: 50%;
			right: 20px;

			vertical-align: middle;

			transform: translateY(-50%);
		}
	}
</style>
