<template>
	<component :is="tag" v-html="html" class="s-markdown"></component>
</template>

<script>
	import truncate from 'lodash/truncate';
	
	export default {
		name: 'SMarkdown',
		props: [
			'tag',
			'value',
			'truncate',
		],
		computed: {
			html() {
				let value = this.value;
				
				if (this.truncate) {
					value = truncate(value, {length: Number(this.truncate)});
				}
				
				let html = this.$md.render(value);

				html = html.replace(/<a.*href="(\/.*)".*>/g, (match, url) =>
				{
					let newUrl = '/' + url
						.replace(/^\/[^\/]+\//g, '')
						.replace(/^\/|\/$/g, '');
					
					return match.replace(url, newUrl);
				});
				
				return html;
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	.s-markdown
	{
    ::v-deep {
      img,
      p img
      {
        max-width: 100%;
      }

      p {
        word-break: break-word;

        &:last-child:not(:first-child),
        &:first-child:last-child {
          margin-bottom: 0;
        }
      }

      ul, ol {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
	}
</style>
