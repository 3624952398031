/**
 * Add light
 * @param color
 * @param amount
 * @returns {string}
 */
const addLight = function (color, amount) {
	let cc = parseInt(color, 16) + amount;
	let c = (cc > 255) ? 255 : (cc);
	c = (c.toString(16).length > 1) ? c.toString(16) : `0${c.toString(16)}`;
	return c;
};

/**
 * Lighten color
 * @param color
 * @param amount
 * @returns {string}
 */
const lighten = (color, amount) => {
	color = (color.indexOf("#") >= 0) ? color.substring(1, color.length) : color;
	amount = parseInt((255 * amount) / 100);
	return color = `#${addLight(color.substring(0, 2), amount)}${addLight(color.substring(2, 4), amount)}${addLight(color.substring(4, 6), amount)}`;
};

/**
 * Subtract light
 * @param color
 * @param amount
 * @returns {string}
 */
const subtractLight = function (color, amount) {
	let cc = parseInt(color, 16) - amount;
	let c = (cc < 0) ? 0 : (cc);
	c = (c.toString(16).length > 1) ? c.toString(16) : `0${c.toString(16)}`;
	return c;
};

/**
 * Darken color
 * @param color
 * @param amount
 * @returns {string}
 */
const darken = (color, amount) => {
	color = (color.indexOf("#") >= 0) ? color.substring(1, color.length) : color;
	amount = parseInt((255 * amount) / 100);
	return color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(color.substring(2, 4), amount)}${subtractLight(color.substring(4, 6), amount)}`;
};

/**
 * Color light or dark
 * @param color
 * @returns {string}
 */
const color = (color) =>
{
	// Variables for red, green, blue values
	let r, g, b, hsp;

	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {

		// If HEX --> store the red, green, blue values in separate variables
		color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

		r = color[1];
		g = color[2];
		b = color[3];
	}
	else {

		// If RGB --> Convert it to HEX: http://gist.github.com/983661
		color = +("0x" + color.slice(1).replace(
			color.length < 5 && /./g, '$&$&'));

		r = color >> 16;
		g = color >> 8 & 255;
		b = color & 255;
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	hsp = Math.sqrt(
		0.299 * (r * r) +
		0.587 * (g * g) +
		0.114 * (b * b)
	);

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > 127.5) {

		return 'light';
	}
	else
	{

		return 'dark';
	}
};

export {
	color,
	lighten,
	darken
}
