<template>
	<main>
		<breadcrumbs :story="story"></breadcrumbs>
		<section v-editable="blok" class="faq-question detail">
			<v-container py-0>
				<v-layout my-0>
					<v-flex  xs12 lg10 offset-lg1 xl8 offset-xl2>
						<article class="item">

							<div v-if="blok.title || blok.date || blok.text" class="content">
								<h1 v-if="blok.title">
									{{ blok.title|replaceText }}
								</h1>

								<s-markdown
									:tag="'div'"
									:value="blok.text"
									class="text"
								/>

								<div class="button">
									<s-button-link
										:link="backUrl"
										:linkText="'Terug naar overzicht'"
									>
										{{ 'Terug naar overzicht' }}
									</s-button-link>
								</div>
							</div>

						</article>
					</v-flex>
				</v-layout>
			</v-container>
		</section>
	</main>
</template>

<script>
	import SButtonLink from '@/components/util/SButtonLink';
	import SMarkdown from '@/components/util/SMarkdown';

	import Breadcrumbs from '@/components/layout/Breadcrumbs';

	export default {
		name: 'FaqQuestionPage',
		props: [
			'story',
			'blok'
		],
		components: {
			SButtonLink: SButtonLink,
			SMarkdown: SMarkdown,
			Breadcrumbs: Breadcrumbs
		},
		computed: {
			backUrl()
			{
				return this.$store.state.route.from || this.stringUrl('/vragen');
			}
		}
	}
</script>

<style lang="scss">
	main
	{
		.faq-question.detail
		{
			margin: 0;
			padding: 50px 0;

			background-color: #eeeeee;

			border-bottom: 1px solid #d3d3d3;

			.item
			{
				height: 100%;
				width: 100%;

				position: relative;
				z-index: 2;

				background-color: #ffffff;

				border-radius: 5px;

				overflow: hidden;

				> .content
				{
					padding: 20px;

          .button
          {
            margin-top: 25px;
          }
				}
			}
		}
	}
</style>
