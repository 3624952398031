<template>
	<div class="pricing-option-button">
		<div class="button">
			<s-button-link
				:link="option.link"
				:linkText="option.linkText || 'Lees meer'"
				:linkClass="'has-icon'"
				:linkStyle="{backgroundColor: bgColor && bgColor.color}"
			>
				{{ option.linkText || 'Lees meer' }}<span class="material-icons">keyboard_arrow_right</span>
			</s-button-link>
		</div>
	</div>
</template>

<script>
	import SButtonLink from '@/components/util/SButtonLink'

	export default {
		name: 'PricingOptionButton',
		props: [
			'block',
			'option',
			'bgColor'
		],
		components: {
			SButtonLink: SButtonLink
		}
	}
</script>

<style lang="scss" scoped>
	.pricing-option-button
	{
		margin: 0;
		padding: 15px;

		text-align: center;
	}
</style>
