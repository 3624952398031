import {
  getConfig,
  getConfigByRoot
} from '@/assets/js/config';

export default async ({ query, req, store }) => {
  if (!store.state.config.host) {
    let config = null;
    
    let editMode = false;
    if (query._storyblok || (
      typeof window !== 'undefined' &&
      window.sessionStorage.getItem('_storyblok_draft_mode')
    )) {
      editMode = true;
    }
    
    let host = req.headers.host || window.location.host;
    let url = req.url || window.location.pathname;
    
    if (process.env.USE_DOMAIN && process.env.USE_DOMAIN.length > 1) {
      config = getConfig(process.env.USE_DOMAIN);
    } else if (!editMode) {
      config = getConfig(host);
    } else {
      config = getConfigByRoot(url);
    }
    
    if (!config) {
      config = {
        host: '19309-stadsparkeren.dokku1.softmedia.nl',
        site: 'stadsparkeren',
        root: '/stadsparkeren',
        type: 'test'
      }
    }
    
    await store.dispatch('config/loadConfig', config);
  }
}