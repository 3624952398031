<template>
	<section v-editable="blok" class="blok-faq-subject">
		<v-layout justify-center wrap>
			<v-flex v-for="question in questions" :key="question.uuid" x12 sm6 lg4>
				<article class="item">
					<div class="content">
						<s-link-wrapper
							v-if="question.content.title"
							:tag="'h3'"
							:link="question.uuid|story_url"
							:linkText="question.content.title|replaceText"
						>
							{{ question.name|replaceText }}
						</s-link-wrapper>

						<div v-if="question.content.shortText" class="text">
							<p>{{ question.content.shortText|replaceText }}</p>
						</div>
						<s-markdown
							v-else-if="question.content.text"
							:tag="'div'"
							:value="question.content.text|replaceText"
							truncate="140"
							class="text"
						/>

						<div class="button">
							<s-button-link
								:link="question.uuid|story_url"
								:linkText="'Lees meer'"
							>
								{{ 'Lees meer' }}
							</s-button-link>
						</div>
					</div>
				</article>
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
	import SButtonLink from '@/components/util/SButtonLink';
	import SMarkdown from '@/components/util/SMarkdown';
	import SLinkWrapper from '@/components/util/SLinkWrapper';

	export default {
		name: 'FaqSubject',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SButtonLink: SButtonLink,
			SMarkdown: SMarkdown,
			SLinkWrapper: SLinkWrapper
		},
		data() {
			return {
				questions: []
			}
		},
		async mounted() {
			await this.loadQuestions();
		},
		methods: {
			async loadQuestions()
			{
				let stories = [];
				let count = 0;
				let total = 0;
				let page = 1;

				do {
					const res = await this.$storyapi.get('cdn/stories', {
						starts_with: this.story.full_slug,
						page: page,
						per_page: 100,
						filter_query : {
							component: {
								in: 'page-faq-question'
							},
						},
						sort_by: 'position:asc'
					});

					if (!res.data.stories) {
						throw 'Unable to retreive stories';
					}

					stories = [
						...stories,
						...res.data.stories
					];

					count += res.data.stories.length;
					total = parseInt(res.headers.total);

					page++;
				}
				while (count < total);
				
				const website = this.$store.state.config.site;
				
				this.questions = stories
					.filter(story => story.content.showOn.includes(website));
			}
		}
	}
</script>

<style lang="scss" scoped>
	.blok-faq-subject
	{
		margin: 0;
		padding: 12px 0;

		text-align: left;

		position: relative;
		z-index: 1;

		.item
		{
			height: 100%;
			width: 100%;

			position: relative;
			z-index: 2;

			background-color: #ffffff;

			border: 1px solid #d3d3d3;
			border-radius: 5px;

			overflow: hidden;

			> .content
			{
				height: 100%;

				padding: 20px;

				position: relative;

				> .text
				{
					margin: 0;
					padding-bottom: 75px;

					font-size: 0.9rem;
				}

				> .button
				{
					position: absolute;
					bottom: 20px;
				}
			}
		}
	}
</style>
