import axios from 'axios';
import moment from 'moment';

/**
 * Storyblok api
 * @type {AxiosInstance}
 */
const storyApi = axios.create({
	baseURL: 'https://api.storyblok.com/v1/',
	params: {
		token: process.env.STORYBLOK_ACCESS_TOKEN
	}
});

/**
 * Get cache version
 *
 * @returns {Promise<string>}
 */
let cacheVersion = null;
const getCacheVersion = async () =>
{
	if (cacheVersion === null) {
		try
		{
			const res = await storyApi.get('cdn/spaces/me');

			cacheVersion = res.data.space.version;
		} catch (e) {
			throw 'Unable to retrieve cache version';
		}
	}

	return cacheVersion;
};

/**
 * Get story
 * @param story
 * @returns {Promise<boolean|*>}
 */
const getStory = async (path) =>
{
	const cacheVersion = await getCacheVersion();

	let story = null;
	try {
		const res = await storyApi.get(`cdn/stories${path}`, {
			params: {
				cv: cacheVersion,
				version: 'published'
			}
		});

		story = res.data.story;
	} catch (e) {
		throw 'Unable to retrieve story';
	}

	return story;
};

export default async (req, res, next) => {

  let root = '';

  const pages = [
    '/mededelingen-iphone',
    '/mededelingen-windows-phone',
    '/mededelingen-android',
    '/algemene-voorwaarden'
  ];

  for (let page of pages)
  {
    let url = root + page;
    if (req.url.startsWith(url))
    {
      let path = `/stadsparkeren${page}`;
      let story = null;

      try {
        story = await getStory(path);
      } catch (e) {
        try {
          path = `/algemeen${page}`;

          story = await getStory(path);
        } catch (e) {
          console.error(e);
        }
      }

      if (story) {
        res.setHeader('last-modified', moment(story.published_at).format('ddd, DD MMM YYYY HH:mm:ss') + ' GMT');
      }
    }
  }

	next();
}
