<template>
	<div :class="columnClass" :style="{minHeight: minHeight}">

		<div v-if="blok.image" :class="imageClass">
			<div class="inner" :style="{paddingTop: imageRatio}">
				<img v-if="editMode"
				     :src="blok.image|resize('1000x0/smart')"
				     :alt="blok.imageAlt"
				/>
				<img v-else
				     :data-src="blok.image|resize('1000x0/smart')"
				     :alt="blok.imageAlt"
				     class="lazyload"
				     data-expand="-100"
				/>
			</div>
		</div>

		<div v-else-if="blok.videoFile || blok.youtube || blok.vimeo" class="video">
			<div class="inner">
				<video v-if="blok.videoFile" controls>
					<source :src="blok.videoFile" type="video/mp4">
					Je browser ondersteund dit niet.
				</video>

				<iframe
					v-else-if="blok.youtube"
					:src="`//www.youtube.com/embed/${blok.youtube}`"
					allowfullscreen
					allow="fullscreen"
					frameborder="0"
				></iframe>

				<iframe
					v-else-if="blok.vimeo"
					:src="`//player.vimeo.com/video/${blok.vimeo}`"
					allowfullscreen
					allow="fullscreen"
					frameborder="0"
				></iframe>
			</div>
		</div>

		<div v-else-if="blok.text || is_link(blok.link)" class="content">

			<s-markdown
				v-if="blok.text"
				:tag="'div'"
				:value="blok.text"
				class="text"
			/>

			<div class="button">
        <s-button-link
          :link="blok.link"
          :linkText="blok.linkText || 'Lees meer'"
					:linkStyle="buttonStyle"
        >
          {{ blok.linkText || 'Lees meer' }}
        </s-button-link>
      </div>

		</div>
	</div>
</template>

<script>
	import SButtonLink from '@/components/util/SButtonLink';
	import SMarkdown from '@/components/util/SMarkdown';

	export default {
		name: 'ColumnsBlock',
		props: [
			'blok',
			'side',
			'size'
		],
		components: {
			SButtonLink: SButtonLink,
			SMarkdown: SMarkdown
		},
		data() {
			return {
				editMode: this.$store.state.editMode || false
			}
		},
		computed: {
			flexClass() {
				if (this.blok.image && this.blok.imageStyle === 'fullwidth') {
					return `xs12 md${this.size}`;
				} else if (this.side === 'left') {
					return `xs12 md${this.size - 1} offset-md1`;
				} else {
					return `xs12 md${this.size - 1}`;
				}
			},
			columnClass()
			{
				return `column column-${this.side} align-text-${this.blok.align}`;
			},
			imageClass()
			{
				return `image ${this.blok.imageStyle}`;
			},
			imageRatio()
			{
				const ratio = this.getImageRatio;
				if (!ratio) {
					return false;
				}

				return (ratio * 100) + '%';
			},
			getImageRatio()
			{
				const src = this.blok.image;
				const values = !src ? [] : src.split('/');

				if (values.length)
				{
					const size = values[5].split('x');
					return size[1] / size[0];
				}

				return false;
			},
			buttonStyle() {
				if (this.blok.backgroundColor === 'primary') {
					return {
						backgroundColor: this.$store.state.personalisation.primaryColor && this.$store.state.personalisation.primaryColor.color
					};
				} else if (this.blok.backgroundColor === 'secondary') {
					return {
						backgroundColor: this.$store.state.personalisation.secondaryColor && this.$store.state.personalisation.secondaryColor.color
					};
				}

				return null;
			}
		},
		methods: {
			minHeight() {
				const ratio = this.getImageRatio;
				if (!ratio) {
					return false;
				}

				if (this.blok.imageStyle === 'inline') {
					return false;
				}

				const size = 12 / Number(this.size);

				return `calc(((100vw - 100%) / ${size}) * ${ratio})`;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.column
	{
		height: 100%;

		position: relative;

		display: flex;
		flex-direction: column;
		justify-content: center;

		.image
		{
			height: 100%;

			position: relative;

			> .inner
			{
				> img
				{
					height: 100%;

					position: absolute;
					top: 0;
				}
			}

			&.inline
			{
				height: auto;

				margin: 0;
				padding: 50px 25px;

				> .inner
				{
					position: relative;
				}
			}
		}

		.video
		{
			margin: 0;
			padding: 24px;

			.inner
			{
				height: 0;

				position: relative;

				padding-bottom: 56.25%;

				> video,
				> iframe
				{
					width: 100%;

					display: block;

					position: absolute;
					top: 0;
					left: 0;
				}

				> iframe
				{
					height: 100%;
				}

				> video
				{
					margin: 0;
					padding: 0;

					border: 1px solid #d3d3d3;
				}
			}

		}

		.content
		{
			margin: 0;
			padding: 50px 24px;

			text-align: left;

			::v-deep
			{
				h1,
				h2,
				h3,
				h4,
				h5,
				h6
				{
					display: inline-block;

					position: relative;

					&:before
					{
						content: '';

						height: 1px;

						margin: 0;

						position: absolute;
						left: 0;
						right: 0;
						bottom: -12.5px;

						background-color: #d3d3d3;
					}
				}
			}

			.text
			{
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5;
			}

      .button
      {
        margin-top: 25px;
      }
		}

		&.column-left
		{
			.image > .inner > img
			{
				right: 0;
			}
		}

		&.column-right
		{
			.image> .inner > img
			{
				left: 0;
			}
		}

		&.align-text-center
		{
			.content
			{
				text-align: center;
			}
		}

		&.align-text-justify
		{
			.content
			{
				text-align: justify;
			}
		}
	}
</style>
