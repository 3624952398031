<template>
	<section v-editable="blok" v-if="articles.length" class="blok-news">
		<div class="indent" :style="indentStyle"></div>
		<v-layout wrap>
			<v-flex v-for="article in articles" :key="article._uid" xs12 sm6 lg4>
				<article class="item" :class="{'no-image': !article.content.image }">

					<div v-if="article.content.date" class="date">
						<span class="day">{{ article.content.date|formatDate('D') }}</span>
						<span class="month">{{ article.content.date|formatDate('MMM') }}</span>
						<span class="year">{{ article.content.date|formatDate('YYYY') }}</span>
					</div>

					<s-link-wrapper
						v-if="article.content.image"
						:tag="'div'"
						:link="article.uuid|story_url"
						:linkText="article.content.imageAlt || article.content.title|replaceText(replacementText)"
						class="image"
					>
						<img v-if="editMode"
						     :src="article.content.image|resize('800x0/smart')"
						     :alt="article.content.imageAlt || article.content.title|replaceText(replacementText)"
						/>
						<img v-else
						     :data-src="article.content.image|resize('800x0/smart')"
						     :alt="article.content.imageAlt || article.content.title|replaceText(replacementText)"
						     class="lazyload"
						     data-expand="-100"
						/>
					</s-link-wrapper>

					<div class="content">

						<s-link-wrapper
							v-if="article.content.title"
							:tag="'h3'"
							:link="article.uuid|story_url"
							:linkText="article.content.title|replaceText(replacementText)"
						>
							{{ article.content.title|replaceText(replacementText) }}
						</s-link-wrapper>

						<div v-if="article.content.shortText" class="text">
							<p>{{ article.content.shortText|replaceText(replacementText) }}</p>
						</div>
						<s-markdown
							v-else-if="article.content.text"
							:tag="'div'"
							:value="article.content.text|replaceText(replacementText)"
							truncate="140"
							class="text"
						/>

						<s-link-wrapper
							:tag="'p'"
							:link="article.uuid|story_url"
							:linkText="'Lees meer'"
						>
							{{ 'Lees meer' }}
						</s-link-wrapper>

					</div>
				</article>
			</v-flex>
		</v-layout>

		<v-layout v-if="blok.show === 'all' && totalPages > 1" justify-center my-0>
			<v-flex>
				<s-pagination :totalPages="totalPages" />
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
	import SLinkWrapper from '@/components/util/SLinkWrapper'
	import SMarkdown from '@/components/util/SMarkdown';
	import SPagination from '@/components/util/SPagination'

	export default {
		name: 'News',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SLinkWrapper: SLinkWrapper,
			SMarkdown: SMarkdown,
			SPagination: SPagination
		},
		data() {
			return {
				editMode: this.$store.state.editMode || false,

				articles: [],
				total: 0
			}
		},
		watch: {
			blok: {
				async handler() {
					await this.loadArticles();
				},
				deep: true
			},
			$route: {
				async handler() {
					await this.loadArticles();
				},
				deep: true
			}
		},
		async mounted() {
			await this.loadArticles();
		},
		computed: {
			page() {
				return this.$route.query.page || 1;
			},
			perPage() {
				return this.blok.show === 'all' ? 12 : parseInt(this.blok.show);
			},
			totalPages() {
				return Math.ceil(this.total/this.perPage);
			},
			indentStyle() {
				if (this.blok.backgroundColor === 'primary') {
					return {
						backgroundColor: this.$store.state.personalisation.primaryColor && this.$store.state.personalisation.primaryColor.color
					};
				} else if (this.blok.backgroundColor === 'secondary') {
					return {
						backgroundColor: this.$store.state.personalisation.secondaryColor && this.$store.state.personalisation.secondaryColor.color
					};
				}

				return null;
			},
			replacementText() {
				return {
					'arnhem': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gepast Parkeren'
					}],
					'assen': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'de AsserPas'
					}],
					'gorinchem': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gastvrij Parkeren'
					}],
					'helmond': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gepast Parkeren'
					}],
					'veenendaal': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gepast Parkeren'
					}]
				}
			}
		},
		methods: {
			async loadArticles()
			{
				const website = this.$store.state.config.site;

				const res = await this.$storyapi.get('cdn/stories', {
					starts_with: 'algemeen/nieuws/',
					is_startpage: 0,
					per_page: this.perPage,
					page: this.page,
					filter_query : {
						component: {
							in: 'page-news-detail'
						},
						showOn: {
							in_array: website
						}
					},
					sort_by: 'content.date:desc'
				});

				if (!res.data.stories) {
					console.error('Failed to load resource');
				}

				this.articles = res.data.stories;
				this.total = parseInt(res.headers.total);
			}
		}
	}
</script>

<style lang="scss">
	@import '~vuetify-scss/bootstrap';

	.blok-news
	{
		/*margin: 12px 0;*/
		/*padding: 0;*/

		margin: 0;
		padding: 12px 0;

		text-align: left;

		position: relative;
		z-index: 1;

		.indent
		{
			height: 100%;
			width: calc(100% + 52px);

			display: none;

			position: absolute;
			top: 0;
			left: -26px;

			border-radius: 5px;
		}

		.item
		{
			height: 100%;
			width: 100%;

			position: relative;
			z-index: 2;

			background-color: #ffffff;

			border-radius: 5px;

			overflow: hidden;

			&.no-image
			{
				> .content
				{
					padding-top: 102px;
				}
			}

			> .date
			{
				display: inline-block;

				margin: 0;
				padding: 5px 15px;

				position: absolute;
				top: 0;
				left: 30px;
				z-index: 2;

				color: #ffffff;

				> .day
				{
					display: block;

					font-size: 1.8em;
					font-weight: 700;
					line-height: 1;

					text-align: center;
				}

				> .month
				{
					display: block;

					font-size: 1em;
					font-weight: 700;
					line-height: 1.25;

					text-transform: uppercase;

					text-align: center;
				}

				> .year
				{
					display: block;

					font-size: 0.9em;
					font-weight: 400;
					line-height: 1.25;

					text-align: center;
				}
			}

			> .image
			{
				height: 0;
				width: 100%;

				padding-top: 62.5%;
				/*padding-top: 40%;*/

				position: relative;

				overflow: hidden;

				img,
				a > img
				{
					width: 100%;

					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 1;

					object-fit: cover;

					transform: translate(-50%, -50%);
				}
			}

			> .content
			{
				padding: 20px;

				> h3
				{
					padding-bottom: 10px;
				}

				> .text
				{
					font-size: 1rem;
					font-weight: 400;
					line-height: 1.5;

					white-space: pre-line;
				}

				> p:last-child
				{
					margin-bottom: 0;
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		.blok-news
		{
			&.first
			{
				margin-top: -100px;
				padding-top: 26px;

				.indent
				{
					display: block;
				}
			}
		}
	}
</style>
