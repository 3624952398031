<template>
	<section v-editable="blok" class="blok-search">

		<div class="search">
			<v-layout wrap>
				<v-flex xs12 lg10 offset-lg1>
					<div class="form">
						<v-form @submit.prevent="submit" ref="form" novalidate>
							<v-text-field
								v-model="form.search"
								placeholder="Zoeken"
								prepend-inner-icon="search"
								item-text="title"
								hide-details
								single-line
								clearable
								outlined
							></v-text-field>
						</v-form>
					</div>
				</v-flex>
			</v-layout>
		</div>

		<div class="search-results-wrapper">
			<div v-if="resultsTitle" class="search-results-title">
				<p><strong>{{ resultsTitle }}</strong></p>
			</div>
			<div v-if="results.length" class="search-results">
				<v-layout wrap>
					<v-flex v-for="result in results" :key="result._uid" xs12 sm6 lg4>
						<article class="item">
							<div class="content">
								<s-link-wrapper
									v-if="result.content.search_title || result.content.seo_title || result.name"
									:tag="'h2'"
									:link="result.uuid|story_url"
									:linkText="result.content.title|replaceText"
								>
									{{ result.content.search_title || result.content.seo_title || result.name }}
								</s-link-wrapper>

								<p v-if="result.content.search_description">
									{{ result.content.search_description|replaceText }}
								</p>
								<p v-else-if="result.content.seo_description">
									{{ result.content.seo_description|replaceText }}
								</p>

								<s-link-wrapper
									:tag="'p'"
									:link="result.uuid|story_url"
									:linkText="'Lees meer'"
								>
									{{ 'Lees meer' }}
								</s-link-wrapper>
							</div>
						</article>
					</v-flex>
				</v-layout>
			</div>
		</div>
	</section>
</template>

<script>
	import slug from 'slug';

	import SLinkWrapper from '@/components/util/SLinkWrapper'

	export default {
		name: 'Search',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SLinkWrapper: SLinkWrapper
		},
		data() {
			return {
				form: {
					search: ''
				},

				resultsTitle: '',
				results: []
			}
		},
		async mounted() {
			this.form.search = this.$route.hash.substr(1).replace(/-/g, ' ');

			this.$nextTick(async function()
			{
				if (!this.form.search) {
					this.results = [];
					return;
				}

				const _slug = slug(this.form.search, {
					lower: true
				});

				const hash = `#${_slug}`;

				this.$router.replace({path: this.$route.path, hash: hash});

				await this.searchWebsite();
			});
		},
		methods: {
			async loadStories()
			{
				let stories = [];
				let count = 0;
				let total = 0;
				let page = 1;

				do {
					const res = await this.$storyapi.get('cdn/stories', {
						'search_term': this.form.search,
						'version': 'published',
						'filter_query[seo_indexable][in]': 'yes',
						'sort_by': 'id:asc',
						'per_page': 100,
						'page': page
					});

					if (!res.data.stories) {
						throw 'Unable to retreive stories';
					}

					stories = [
						...stories,
						...res.data.stories
					];

					count += res.data.stories.length;
					total = parseInt(res.headers.total);
					page++;
				}
				while (count < total);

				return stories;
			},
			async getUrls(root)
			{
				const res = await this.$storyapi.get(`cdn/stories${root}/instellingen`);
				if (!res.data.story) {
					return;
				}

				const items = [
					...res.data.story.content.mainNav,
					...res.data.story.content.footerNav
				];

				return items.map(item => item.link.cached_url);
			},
			async searchWebsite()
			{
				const root = this.$store.state.config.root;
				const website = this.$store.state.config.site;

				const urls = await this.getUrls(root);

				const stories = await this.loadStories();

				let filteredStories = [];
				urls.forEach(url =>
				{
					const s = stories.filter(story => story.full_slug.startsWith(url));

					filteredStories = [
						...filteredStories,
						...s
					];
				});
				
				this.results = filteredStories
					.filter((story, index) => filteredStories.indexOf(story) === index)
					.filter(story => !story.content.showOn || (story.content.showOn && story.content.showOn.includes(website)));

				let title = '';
				if (this.results.length === 1) {
					title = `${this.results.length} resultaat gevonden voor "${this.form.search}"`;
				} else if (this.results.length > 1) {
					title = `${this.results.length} resultaten gevonden voor "${this.form.search}"`;
				} else {
					title = `Geen resultaten gevonden voor "${this.form.search}"`;
				}

				this.resultsTitle = title;
			},
			async submit()
			{
				if (!this.form.search)
				{
					this.$router.replace({path: this.$route.path});

					this.resultsTitle = '';
					this.results = [];
					return;
				}

				const _slug = slug(this.form.search, {
					lower: true
				});

				const hash = `#${_slug}`;

				this.$router.replace({path: this.$route.path, hash: hash});

				await this.searchWebsite();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.blok-search
	{
		text-align: left;

		.search
		{
			margin: 0;
			padding: 12px 0;

			::v-deep .v-text-field
			{
				.v-input__control .v-input__slot
				{
					background-color: #ffffff !important;
				}
			}
		}

		.search-results-wrapper
		{
			.search-results-title
			{
				margin: 0;
				padding: 12px 0;
			}

			.search-results
			{
				margin: 0;
				padding: 12px 0;

				.item
				{
					height: 100%;
					width: 100%;

					background-color: #ffffff;

					border: 1px solid #d3d3d3;
					border-radius: 5px;

					overflow: hidden;

					> .content
					{
						padding: 20px;
					}
				}
			}
		}
	}
</style>