<template>
    <main>
        <component :key="blok._uid" v-for="blok in blok.header" :is="blok.component|dashify" :blok="blok"></component>
        <breadcrumbs :story="story"></breadcrumbs>
        <component :key="blok._uid" v-for="blok in blok.main" :is="blok.component|dashify" :story="story" :blok="blok" :indent="indent(blok)"></component>
    </main>
</template>

<script>
  import Breadcrumbs from '@/components/layout/Breadcrumbs';

  export default {
    name: 'ContentPage',
    props: [
      'story',
      'blok'
    ],
    components: {
      Breadcrumbs: Breadcrumbs
    },
    methods: {
      indent(row) {
        if (this.$route.path !== '/') {
          return false;
        }

        if (this.blok.header.length === 0) {
          return false;
        }

        if (this.blok.main[0] !== row) {
          return false;
        }

        return true;
      }
    }
  }
</script>
