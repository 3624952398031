<template>
	<section v-editable="blok" :class="sectionClass" :style="sectionStyle">
		<v-container grid-list-xl>
			<v-layout wrap>
				<v-flex xs12 xl10 offset-xl1>
					<component v-for="block of blok.blocks" :key="block._uid" :is="block.component|dashify" :story="story" :row="blok" :blok="block" :class="{first: firstBlock(block)}"/>
				</v-flex>
			</v-layout>
		</v-container>
	</section>
</template>

<script>
	export default {
		name: 'RowContent',
		props: [
			'story',
			'blok',
			'indent'
		],
		computed: {
			sectionClass() {
				return `row-content bg-color-${this.blok.backgroundColor}`;
			},
			sectionStyle() {
				if (this.blok.backgroundColor === 'primary') {
					return {
						backgroundColor: this.$store.state.personalisation.primaryColor && this.$store.state.personalisation.primaryColor.color
					};
				} else if (this.blok.backgroundColor === 'secondary') {
					return {
						backgroundColor: this.$store.state.personalisation.secondaryColor && this.$store.state.personalisation.secondaryColor.color
					};
				}

				return null;
			}
		},
		methods: {
			firstBlock(block) {
				return this.indent && block === this.blok.blocks[0];
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-content
	{
		margin: 0;
		padding: 14px 0;

		background-color: #ffffff;

		border-bottom: 1px solid #d3d3d3;

		::v-deep section:before
		{
			background-color: #ffffff;
		}

		&.bg-color-white
		{
			background-color: #ffffff;

			::v-deep
			{
				.blok-news,
				.blok-pricing,
				.blok-breakdown,
				.blok-search
				{
					.item
					{
						border: 1px solid #d3d3d3;
					}
				}
			}

			::v-deep .indent
			{
				background-color: #ffffff;
			}

			/*::v-deep .blok-grid .item .content*/
			/*{*/
			/*	padding: 20px 0;*/
			/*}*/
		}

		&.bg-color-grey
		{
			background-color: #eeeeee;

			::v-deep .indent
			{
				background-color: #eeeeee;
			}
		}

		&.bg-color-primary,
		&.bg-color-secondary
		{
			::v-deep
			{
				.blok-text
				{
					.content .text
					{
						h1,
						h2,
						h3,
						h4,
						h5,
						h6,
						h1 a,
						h2 a,
						h3 a,
						h4 a,
						h5 a,
						h6 a,
						p
						{
							color: #ffffff;
						}

						h1:before
						{
							background-color: #ffffff;
						}
					}
				}

				.blok-files .item
				{
					background-color: rgba(0, 0, 0, 0.1);

					border: 1px solid transparent;

					.icon
					{
						span,
						a > span
						{
							color: #ffffff;
						}
					}

					.content p a
					{
						color: #ffffff;
					}
				}

				.blok-table .table tr
				{
					th,
					td
					{
						color: #ffffff;

						background-color: rgba(0, 0, 0, 0.1);

						border: 1px solid rgba(0, 0, 0, 0.4);
					}

					th
					{
						background-color: rgba(0, 0, 0, 0.2);
					}
				}

				.blok-grid .item
				{
					background-color: rgba(0, 0, 0, 0.1);

					> .content
					{
						> h2,
						> h2 a,
						> .text
						{
							color: #ffffff;
						}
					}
				}

				.blok-news .item
				{
					background-color: rgba(0, 0, 0, 0.1);

					> .content
					{
						> h2,
						> h2 a,
						> p,
						> p a,
						> .text
						{
							color: #ffffff;
						}
					}
				}

				.btn
				{
					background-color: rgba(0, 0, 0, 0.1);
				}
			}
		}
	}
</style>