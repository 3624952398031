const redirects = {
	...require('../assets/config/page/redirects.json')
};

const domainRedirects = {
	...require('../assets/config/domain/redirects.json')
};

export default (req, res, next) => {
	let newUrl = null;
	if (/\/$/.exec(req.url)) {
		newUrl = req.url.replace(/\/$/, '');
	}

	if (req.url.startsWith('//')) {
		newUrl = req.url.replace(/^\/\//, '/');
	}

	const hostname = req.headers.host || '';

	if (domainRedirects[hostname]) {
		newUrl = `https://${domainRedirects[hostname]}${req.url}`;
	}

  // redirect old
  if (redirects[hostname] !== undefined) {
    if (redirects[hostname][newUrl || req.url]) {
      newUrl = redirects[hostname][newUrl || req.url];
    } else {
      const relative = (newUrl || req.url).split('?');

      if (redirects[hostname][relative[0]]) {
        newUrl = redirects[hostname][relative[0]] + (relative[1] ? '?' + relative[1] : '');
      }
    }
  }


	if (newUrl) {
		res.writeHead(301, { Location: newUrl });

		return res.end();
	}

	next();
}
