import locales from '@/assets/config/domain/folders.json';

/**
 * get config item by key
 * @param url
 * @returns {*}
 */
export const getConfig = (url) => {
  const key = Object.keys(locales).find(key => url.includes(key));
  
  return locales[key];
};

let cache = {};

/**
 * get config item by root
 * @param slug
 * @returns {}
 */
export const getConfigByRoot = (slug) => {
  if (Object.keys(cache).length === 0) {
    for (let key of Object.keys(locales)) {
      let item = locales[key];
  
      if (process.env.DOMAIN_TYPE !== item.type) {
        continue;
      }
      
      Object.assign(cache, { [item.root]: item });
    }
  }
  
  // const key = Object.keys(cache).find(key => slug.includes(`${key}/`));
  const key = Object.keys(cache).find(root => slug.startsWith(root));
  
  return cache[key];
};