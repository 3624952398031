<template>
	<div class="top">
		<v-container fluid py-0>
			<v-layout wrap my-0>
				<v-flex xs12 md4>
					<div v-if="logo" class="logo">
						<nuxt-link to="/" :title="siteName">
							<img :src="logo" :alt="siteName" />
						</nuxt-link>
					</div>
				</v-flex>
				<v-flex xs12 md8 hidden-md-and-down>
					<div class="quick-menu">
						<ul>
							<li>
								<a href="https://itunes.apple.com/us/app/stadsparkeren/id892308309?ls=1&mt=8" target="_blank" title="Stadsparkeren iPhone app" class="icon">
                  <span class="icon-apple" title="Stadsparkeren iPhone app"></span>
								</a>
							</li>
							<li>
								<a href="https://play.google.com/store/apps/details?id=nl.stadsparkeren" target="_blank" title="Stadsparkeren Android app" class="icon">
                  <span class="icon-android" title="Stadsparkeren Android app"></span>
								</a>
							</li>
							<li class="empty"></li>
							<li v-if="breakdowns.length">
								<nuxt-link :to="stringUrl('/storingen')" title="Mededelingen">
									<span class="material-icons">warning</span>
                  Mededelingen
								</nuxt-link>
							</li>
							<s-link-wrapper
								v-for="item in quickItems"
								:key="item._uid"
								:tag="'li'"
								:link="item.link"
								:linkText="item.name"
							>
								<span
									v-if="item.icon && item.icon.selected"
									class="material-icons"
								>
									{{ item.icon.selected }}
								</span>
								{{ item.name }}
							</s-link-wrapper>
						</ul>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	import SLinkWrapper from '@/components/util/SLinkWrapper';

	export default {
		name: 'HeaderTop',
		props: [
			'breakdowns'
		],
		components: {
			SLinkWrapper: SLinkWrapper
		},
		computed: {
			logo() {
				return this.$store.state.settings && this.$store.state.personalisation.logo || false;
			},
			siteName() {
				return this.$store.state.settings && this.$store.state.settings.siteName || false;
			},
			quickItems() {
				return this.$store.state.settings && this.$store.state.settings.quickNav || [];
			}
		}
	}
</script>

<style lang="scss">
	.top
	{
		height: 120px;
		width: 100%;

		position: relative;

		background-color: #ffffff;

		.logo
		{
			height: 120px;

			margin: 0;
			padding: 10px 0;

			> img,
			> a > img
			{
				height: 100%;
			}
		}

		.quick-menu
		{
			height: 120px;

			position: relative;

			ul
			{
				margin: 0;
				padding: 0;

				position: absolute;
				top: 50%;
				right: 0;

				list-style: none;

				transform: translateY(-50%);

				> li
				{
					height: 40px;

					display: inline-block;

					margin: 0;
					padding: 0;

					line-height: 40px;

					vertical-align: middle;

					&.empty
					{
						padding: 0 20px;
					}

					> a
					{
						height: 100%;

						display: block;

						margin: 0 10px;
						padding: 5px 10px 5px 25px;

						position: relative;

						color: #333333;
						font-size: 0.9rem;
						font-weight: 700;
						line-height: 1.75;

						text-decoration: none;

            span[class^="icon-"],
            span[class*=" icon-"]
            {
              height: 22px;
              width: 22px;

              display: inline-block;

              font-size: 1.1rem;

              vertical-align: bottom;
            }

						span.material-icons
						{
							position: absolute;
							top: 50%;
							left: 0;

							color: #bbb;
							font-size: 1.1rem;

							transform: translateY(-50%);
						}

						&:before
						{
							content: '';

							height: 1px;
							width: 0;

							position: absolute;
							left: 50%;
							bottom: 0;

							background-color: #999999;

							transition: width 0.1s, left 0.1s;
						}

						&:hover,
						&:focus
						{
							&:before
							{
								width: 100%;

								left: 0;
							}
						}

						&.icon
						{
							margin: 0;
              padding: 0 10px;

							&:hover,
							&:focus
							{
								&:before
								{
									content: none;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
