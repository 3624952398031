<template>
	<main>
		<section v-editable="blok" class="row-content bg-color-grey">
			<v-container grid-list-xl>
				<v-layout wrap>
					<v-flex xs12 xl10 offset-xl1>
						<breakdown :row="{}" :blok="{}" />
					</v-flex>
				</v-layout>
			</v-container>
		</section>
	</main>
</template>

<script>
	import Breakdown from '@/components/bloks/Breakdown'

	export default {
		name: 'BreakdownPage',
		props: [
			'blok'
		],
		components: {
			Breakdown: Breakdown
		}
	}
</script>

<style lang="scss" scoped>
	.row-content
	{
		margin: 0;
		padding: 0;

		background-color: #ffffff;

		border-bottom: 1px solid #d3d3d3;

		::v-deep section:before
		{
			background-color: #ffffff;
		}

		&.bg-color-grey
		{
			background-color: #eeeeee;

			::v-deep section:before
			{
				background-color: #eeeeee;
			}
		}
	}
</style>