<template>
	<component :is="tag">
		<s-link-type v-if="isLink" v-bind="linkProps()">
			<slot />
		</s-link-type>
		<slot v-else />
	</component>
</template>

<script>
	import SLinkType from '@/components/util/SLinkType'

	export default {
		name: 'SLinkWrapper',
		props: [
			'tag',
			'link',
			'linkText',
			'linkClass'
		],
		components: {
			SLinkType: SLinkType
		},
		computed: {
			isLink()
			{
				return this.link && (typeof this.link === 'string' || this.is_link(this.link));
			}
		},
		methods: {
			linkProps()
			{
				return {
					link: this.link || false,
					linkText: this.linkText || false,
					linkClass: this.linkClass || false
				}
			}
		}
	}
</script>