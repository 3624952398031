<template>
	<header>
		<HeaderTop :breakdowns="breakdowns" />
		<HeaderNav :breakdowns="breakdowns" />
	</header>
</template>

<script>
	import HeaderTop from '@/components/layout/header/HeaderTop'
	import HeaderNav from '@/components/layout/header/HeaderNav'

	export default {
		name: 'Header',
		components: {
			HeaderTop: HeaderTop,
			HeaderNav: HeaderNav
		},
		data() {
			return {
				breakdowns : []
			}
		},
		async mounted()
		{
			await this.loadBreakdowns();
		},
		methods: {
			async loadStory()
			{
				const res = await this.$storyapi.get('cdn/stories/beheer/storingen');

				if (!res.data.story) {
					console.error('Failed to load resource');
				}

				return res.data.story;
			},
			async loadBreakdowns()
			{
				const story = await this.loadStory();

				const website = this.$store.state.config.site;

				const breakdowns = [];
				story.content.blocks.forEach((breakdown) =>
				{
					if (breakdown.showOn.indexOf(website) === -1) {
						return;
					}

					breakdowns.push(breakdown);
				});

				this.breakdowns = breakdowns;
			}
		}
	}
</script>

<style lang="scss">
	@import '~vuetify-scss/bootstrap';

	header
	{
		height: 180px;
		width: 100%;

		position: relative;
	}
</style>