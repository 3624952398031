<template>
	<section v-editable="blok" v-if="blok.blocks.length" :class="blockClass">
		<div class="indent" :style="indentStyle"></div>
		<v-layout :class="layoutClass">
			<v-flex v-for="block in blok.blocks" :key="block._uid" xs12 sm6 md4 lg3>
				<article class="item">

					<s-link-wrapper
						v-if="block.image"
						:tag="'div'"
						:link="block.link"
						:linkText="block.imageAlt || block.title|replaceText"
						:style="{paddingTop: imageRatio(block.image)}"
						class="image"
					>
						<img v-if="editMode"
						     :src="block.image|resize('800x0/smart')"
						     :alt="block.imageAlt || block.title|replaceText"
						/>
						<img v-else
						     :data-src="block.image|resize('800x0/smart')"
						     :alt="block.imageAlt || block.title|replaceText"
						     class="lazyload"
						/>
					</s-link-wrapper>

					<div v-if="block.title || block.text" class="content">

						<s-link-wrapper
							v-if="block.title"
							:tag="block.heading"
							:link="block.link"
							:linkText="block.title|replaceText"
						>
							{{ block.title|replaceText }}
						</s-link-wrapper>

						<p v-if="block.text" class="text">{{ block.text|replaceText }}</p>
					</div>

				</article>
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
	import SLinkWrapper from '@/components/util/SLinkWrapper'

	export default {
		name: 'Grid',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SLinkWrapper: SLinkWrapper
		},
		data() {
			return {
				editMode: this.$store.state.editMode || false
			}
		},
		computed: {
			blockClass() {
				return `blok-grid align-text-${this.blok.align}`;
			},
			layoutClass() {
				return `wrap justify-${this.blok.alignGrid}`;
			},
			indentStyle() {
				if (this.blok.backgroundColor === 'primary') {
					return {
						backgroundColor: this.$store.state.personalisation.primaryColor && this.$store.state.personalisation.primaryColor.color
					};
				} else if (this.blok.backgroundColor === 'secondary') {
					return {
						backgroundColor: this.$store.state.personalisation.secondaryColor && this.$store.state.personalisation.secondaryColor.color
					};
				}

				return null;
			}
		},
		methods: {
			imageRatio(image)
			{
				const ratio = this.getImageRatio(image);
				if (!ratio) {
					return false;
				}

				return (ratio * 100) + '%';
			},
			getImageRatio(image)
			{
				const src = image;
				const values = !src ? [] : src.split('/');

				if (values.length)
				{
					const size = values[5].split('x');
					return size[1] / size[0];
				}

				return false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '~vuetify-scss/bootstrap';

	.blok-grid
	{
		margin: 0;
		padding: 12px 0;

		text-align: left;

		position: relative;
		z-index: 1;

		.indent
		{
			height: 100%;
			width: calc(100% + 52px);

			display: none;

			position: absolute;
			top: 0;
			left: -26px;

			border-radius: 5px;
		}

		.item
		{
			height: 100%;
			width: 100%;

			position: relative;
			z-index: 2;

			background-color: #ffffff;

			border-radius: 5px;

			overflow: hidden;

			.image
			{
				height: 0;
				width: 100%;

				padding-top: 62.5%;

				position: relative;

				overflow: hidden;

				img,
				a > img
				{
					width: 100%;

					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 1;

					object-fit: cover;

					transform: translate(-50%, -50%);
				}
			}

			> .content
			{
				padding: 15px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 5px;
        }

				> .text
				{
					font-size: 1rem;
					font-weight: 400;
					line-height: 1.5;

					white-space: pre-line;
				}

			}
		}

		&.align-text-center
		{
			text-align: center;
		}
	}

	@include breakpoint(md-and-up)
	{
		.blok-grid
		{
			&.first
			{
				margin-top: -100px;
				padding-top: 14px;

				.indent
				{
					display: block;
				}
			}
		}
	}
</style>
