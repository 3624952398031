<template>
	<section v-editable="blok" class="blok-faq-overview">
		<div class="faq-search">
			<v-layout wrap>
				<v-flex xs12 lg10 offset-lg1>
					<div class="item">
						<div class="content">
							<v-autocomplete
								:items="autocompleteQuestions"
								:filter="filterResults"
								placeholder="Zoek uw vraag"
								prepend-inner-icon="search"
								item-text="content.title"
								return-object
								hide-details
								single-line
								clearable
								outlined
								@input="openQuestion"
							></v-autocomplete>
						</div>
					</div>
				</v-flex>
			</v-layout>
		</div>

		<div v-if="latest.length" class="faq-latest">
			<v-layout wrap>
				<v-flex xs12>
					<div class="item">
						<div class="content">
							<h3>Meestgestelde vragen</h3>
							<ul>
								<s-link-wrapper
									v-for="question in latest"
									:key="question.uuid"
									:tag="'li'"
									:link="question.uuid|story_url"
									:linkText="question.content.title|replaceText"
								>
									{{ question.content.title|replaceText }}
								</s-link-wrapper>
							</ul>
						</div>
					</div>
				</v-flex>
			</v-layout>
		</div>

		<div class="faq-subjects">
			<v-layout justify-center wrap>
				<v-flex v-for="category in columns" :key="category.uuid" x12 sm6 lg4>
					<article class="item">
						<div class="content">
							<s-link-wrapper
								v-if="category.name"
								:tag="'h3'"
								:link="category.uuid|story_url"
								:linkText="category.name"
							>
								{{ category.name }}
							</s-link-wrapper>

							<ul>
								<s-link-wrapper
									v-for="question in category.questions.slice(0, 5)"
									:key="question.uuid"
									:tag="'li'"
									:link="question.uuid|story_url"
									:linkText="question.content.title|replaceText"
								>
									{{ question.content.title|replaceText }}
								</s-link-wrapper>
							</ul>

							<div class="button">
								<s-button-link
									:link="category.uuid|story_url"
									:linkText="`Alles over ${category.name.toLowerCase()}`"
								>
									{{ `Alles over ${category.name.toLowerCase()}` }}
								</s-button-link>
							</div>
						</div>
					</article>
				</v-flex>
			</v-layout>
		</div>
	</section>
</template>

<script>
	import SButtonLink from '@/components/util/SButtonLink';
	import SLinkWrapper from '@/components/util/SLinkWrapper'

	export default {
		name: 'FaqOverview',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SButtonLink: SButtonLink,
			SLinkWrapper: SLinkWrapper
		},
		data() {
			return {
				categories: [],
				questions: []
			}
		},
		async mounted() {
			await this.loadCategories();
			await this.loadQuestions();
		},
		computed: {
			autocompleteQuestions()
			{
				const website = this.$store.state.config.site;

				return this.questions
					.filter(question => question.content.showOn.includes(website))
					.sort((a, b) => {
						if(a.content.title < b.content.title) { return -1; }
						if(a.content.title > b.content.title) { return 1; }
						return 0;
					});
			},
			latest()
			{
				const website = this.$store.state.config.site;

				const questions = this.questions
					.filter(question => question.content.highlight && question.content.showOn.includes(website))
					.sort((a, b) => {
						if(a.content.title < b.content.title) { return -1; }
						if(a.content.title > b.content.title) { return 1; }
						return 0;
					});

				return questions;
			},
			columns()
			{
				const website = this.$store.state.config.site;

				const columns = [];
				(this.categories || []).forEach(category =>
				{
					const questions = this.questions
						.filter(question => category.parent_id === question.parent_id && question.content.showOn.includes(website));

					if (!questions.length)
					{
						return;
					}

					columns.push
					({
						...category,
						questions: questions
					})
				});

				return columns;
			}
		},
		methods: {
			async loadCategories()
			{
				let stories = [];
				let count = 0;
				let total = 0;
				let page = 1;

				do {
					const res = await this.$storyapi.get('cdn/stories', {
						starts_with: this.story.full_slug,
						page: page,
						per_page: 100,
						filter_query : {
							component: {
								in: 'page-faq-subject'
							},
						}
					});

					if (!res.data.stories) {
						throw 'Unable to retreive stories';
					}

					stories = [
						...stories,
						...res.data.stories
					];

					count += res.data.stories.length;
					total = parseInt(res.headers.total);

					page++;
				}
				while (count < total);

				const links = Object.values((await this.$storyapi.get('cdn/links', {
					starts_with: this.story.full_slug
				}))
				.data.links);

				this.categories = stories.sort((a, b) =>
				{
					const posA = links.find(link => link.id === a.parent_id).position;
					const posB = links.find(link => link.id === b.parent_id).position;

					return posA - posB;
				});
			},
			async loadQuestions()
			{
				let stories = [];
				let count = 0;
				let total = 0;
				let page = 1;

				do {
					const res = await this.$storyapi.get('cdn/stories', {
						starts_with: this.story.full_slug,
						page: page,
						per_page: 100,
						filter_query : {
							component: {
								in: 'page-faq-question'
							},
						},
						sort_by: 'position:asc'
					});

					if (!res.data.stories) {
						throw 'Unable to retreive stories';
					}

					stories = [
						...stories,
						...res.data.stories
					];

					count += res.data.stories.length;
					total = parseInt(res.headers.total);

					page++;
				}
				while (count < total);

				this.questions = stories;
			},
			filterResults(item, queryText, itemText)
			{
				const title = this.replaceText(item.content.title).toLowerCase();
				const text = this.replaceText(item.content.text).toLowerCase();

				const searchText = queryText.toLowerCase();

				return title.indexOf(searchText) > -1 || text.indexOf(searchText) > -1
			},
			openQuestion(question)
			{
				if (!question) {
					return;
				}

				this.$router.push({path: this.story_url(question.uuid)});
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '~vuetify-scss/bootstrap';

	.blok-faq-overview
	{
		.faq-search
		{
			margin: 0;
			padding: 12px 0;

			::v-deep .v-text-field
			{
				.v-input__control .v-input__slot
				{
					background-color: #ffffff !important;
				}
			}
		}

		.faq-latest
		{
			margin: 0;
			padding: 12px 0;

			.item
			{
				height: 100%;
				width: 100%;

				background-color: #ffffff;

				border: 1px solid #d3d3d3;
				border-radius: 5px;

				overflow: hidden;

				.content
				{
					padding: 20px;

					> ul
					{
						margin: 0;
						padding: 0;

						list-style: none;

						> li
						{
							display: block;

							margin: 0;
							padding: 0;

							::v-deep a
							{
								display: block;

								margin: 0;
								padding: 0 0 0 25px;

								position: relative;

								font-size: 0.9rem;
								font-weight: 400;
								line-height: 2;

								&:before
								{
									content: 'keyboard_arrow_right';

									position: absolute;
									top: 6px;
									left: -5px;

									font-family: 'Material Icons';
									font-feature-settings: 'liga';
									font-weight: normal;
									font-style: normal;
									font-size: 1.2rem;
									line-height: 1;

									transition: left 0.2s;
								}

								&:hover
								{
									font-weight: 700;

									&:before
									{
										left: 0;
									}
								}
							}
						}
					}
				}
			}
		}

		.faq-subjects
		{
			.item
			{
				height: 100%;
				width: 100%;

				position: relative;
				z-index: 2;

				background-color: #ffffff;

				border: 1px solid #d3d3d3;
				border-radius: 5px;

				overflow: hidden;

				> .content
				{
					height: 100%;

					padding: 20px;

					position: relative;

					> ul
					{
						margin: 0;
						padding-left: 0;
						padding-bottom: 75px;

						list-style: none;

						> li
						{
							display: block;

							margin: 0;
							padding: 0;

							::v-deep a
							{
								display: block;

								margin: 0;
								padding: 0 0 0 25px;

								position: relative;

								font-size: 0.9rem;
								font-weight: 400;
								line-height: 2;

								&:before
								{
									content: 'keyboard_arrow_right';

									position: absolute;
									top: 6px;
									left: -5px;

									font-family: 'Material Icons';
									font-feature-settings: 'liga';
									font-weight: normal;
									font-style: normal;
									font-size: 1.2rem;
									line-height: 1;

									transition: left 0.2s;
								}

								&:hover
								{
									font-weight: 700;

									&:before
									{
										left: 0;
									}
								}
							}
						}
					}

					> .button
					{
						position: absolute;
						bottom: 20px;
					}
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		.blok-faq-overview
		{
			.faq-latest .item .content > ul
			{
				-webkit-columns: 2;
				column-count: 2;

				column-gap: 1.25rem;

				column-break-inside: avoid;
				page-break-inside: avoid;
				-webkit-column-break-inside: avoid;
				break-inside: avoid;
			}
		}
	}

	@include breakpoint(lg-and-up)
	{
		.blok-faq-overview
		{
			.faq-latest .item .content > ul
			{
				-webkit-columns: 3;
				column-count: 3;

				column-gap: 1.25rem;

				column-break-inside: avoid;
				page-break-inside: avoid;
				-webkit-column-break-inside: avoid;
				break-inside: avoid;
			}
		}
	}
</style>
