<template>
	<section v-editable="blok" v-if="blok.blocks.length" class="blok-files">
		<v-layout wrap my-0>
			<v-flex v-for="block in blok.blocks" :key="block._uid" xs6 sm4 md3 lg2>
				<article class="item">
					<s-link-wrapper
						v-if="block.fileIcon"
						:tag="'div'"
						:link="block.file"
						:linkText="block.fileText"
						class="icon"
					>
						<span
							v-if="block.fileIcon && block.fileIcon.selected"
							class="material-icons"
						>
							{{ block.fileIcon.selected }}
						</span>
					</s-link-wrapper>

					<div v-if="block.fileText" class="content">
						<s-link-wrapper
							v-if="block.fileText"
							:tag="'p'"
							:link="block.file"
							:linkText="block.fileText"
						>
							{{ block.fileText }}
						</s-link-wrapper>
					</div>
				</article>
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
	import SLinkWrapper from '@/components/util/SLinkWrapper';

	export default {
		name: 'Files',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			SLinkWrapper: SLinkWrapper
		}
	}
</script>

<style lang="scss" scoped>
	@import '~vuetify-scss/bootstrap';

	.blok-files
	{
		margin: 0;
		padding: 12px 0;

		text-align: left;

		position: relative;
		z-index: 1;

		.item
		{
			height: 100%;
			width: 100%;

			position: relative;
			z-index: 2;

			background-color: #ffffff;

			border: 1px solid #d3d3d3;
			border-radius: 5px;

			overflow: hidden;

			.icon
			{
				height: 0;
				width: 100%;

				padding-top: 80%;

				position: relative;

				overflow: hidden;

				span,
				a > span
				{
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 1;

					font-size: 7rem;

					transform: translate(-50%, -50%);
				}
			}

			.content
			{
				padding: 10px;

				p
				{
					margin: 0;
					padding: 0;
				}
			}
		}
	}
</style>