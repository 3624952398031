<template>
	<section class="header-video">
		<div class="inner">
			<video v-if="blok.video" preload="none" autoplay loop muted>
				<source :src="blok.video" type="video/mp4">
			</video>

			<div v-if="blok.image" class="image">
				<img :src="blok.image|resize('2000x0')" :alt="blok.imageAlt" />
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'HeaderVideo',
		props: [
			'blok'
		],
		methods: {
			resize()
			{

			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '~vuetify-scss/bootstrap';

	.header-video
	{
		margin: 0;
		padding: 0;

		position: relative;
		z-index: 1;

		> .inner
		{
			width: 100%;

			margin: 0;
			padding-top: 460px;

			position: relative;

			overflow: hidden;

			> video
			{
				height: auto;
				min-height: 100%;
				width: auto;
				min-width: 100%;

				display: none;

				position: absolute;
				top: 0;
				left: 50%;

				transform: translate(-50%, 0);
			}

			> .image
			{
				height: 100%;

				img
				{
					height: 100%;
					width: 100%;

					display: block;

					position: absolute;
					top: 50%;
					left: 50%;

					object-fit: cover;

					transform: translate(-50%, -50%);
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		section.header-video
		{
			> .inner
			{
				padding-top: 50%;

				> video
				{
					display: block;
				}

				> .image
				{
					display: none;
				}
			}
		}
	}

	@include breakpoint(lg-and-up)
	{
		section.header-video
		{
			> .inner
			{
				padding-top: 680px;
			}
		}
	}
</style>