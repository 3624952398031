<template>
	<section v-if="items.length" class="breadcrumbs">
		<v-container grid-list-xl py-0>
			<v-layout>
				<v-flex>
					<ul>
						<li>
							<nuxt-link to="/" title="Home">
								Home
							</nuxt-link>
						</li>
						<li v-for="item in items" :key="item.uuid">
							<nuxt-link :to="item.uuid|story_url">
								{{ item.name|replaceText(replacementText) }}
							</nuxt-link>
						</li>
					</ul>
				</v-flex>
			</v-layout>
		</v-container>
	</section>
</template>

<script>
	export default {
		name: 'Breadcrumbs',
		props: [
			'story',
			'blok'
		],
		computed: {
			links() {
				return Object.values(this.$store.state.links || {});
			},

			items() {
				let { lang, root, prefix } = this.$store.state.config;

				let rootPath = prefix ?
					root + '/' + lang :
					root;

				let rootPathLength = rootPath.split('/').filter(Boolean).length;

				let slug = '';
				const items = [];

				const slices = this.story.full_slug
					.replace('home', '')
					.split('/')
					.filter(Boolean);

				for (const [index, slice] of slices.entries()) {
					slug += `${slice}/`;

					if (index < rootPathLength) {
						continue;
					}

					const withoutTrailingSlash =  slug.replace(/\/$/, '');

					const link = this.links.find(link => link.slug === slug) || this.links.find(link => link.slug === withoutTrailingSlash);
					if (!link) {
						break;
					}

					items.push(link);
				}

				return items;
			},

			replacementText() {
				return {
					'arnhem': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gepast Parkeren'
					}],
					'assen': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'de AsserPas'
					}],
					'gorinchem': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gastvrij Parkeren'
					}],
					'helmond': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gepast Parkeren'
					}],
					'veenendaal': [{
						regex: /(stadsparkeren)(?!(\.nl| app))/gi,
						value: 'Gepast Parkeren'
					}]
				}
			}
		}
	}
</script>

<style lang="scss">
	section.breadcrumbs
	{
		margin: 0;
		padding: 20px 0;

		background-color: #ffffff;

		border-bottom: 1px solid #d3d3d3;

		ul
		{
			margin: 0;
			padding: 0;

			list-style: none;

			> li
			{
				display: inline-block;

				position: relative;

				vertical-align: top;

				> a
				{
					display: block;

					position: relative;

					margin-right: 30px;
					padding: 0;

					font-size: 0.9rem;
					font-weight: 400;
					line-height: 1.5;

					/*&:before*/
					/*{*/
					/*	content: '';*/

					/*	height: 1px;*/
					/*	width: 0;*/

					/*	position: absolute;*/
					/*	left: 50%;*/
					/*	bottom: 0;*/

					/*	background-color: #999999;*/

					/*	transition: width 0.1s, left 0.1s;*/
					/*}*/

					/*&:hover,*/
					/*&:focus*/
					/*{*/
					/*	&:before*/
					/*	{*/
					/*		width: 100%;*/

					/*		left: 0;*/
					/*	}*/
					/*}*/
				}

				&:after
				{
					content: 'keyboard_arrow_right';

					position: absolute;
					top: 50%;
					right: 5px;

					font-family: 'Material Icons';
					font-feature-settings: 'liga';
					font-weight: normal;
					font-style: normal;
					font-size: 1rem;
					line-height: 1;

					transform: translateY(-50%);
				}

				&:last-child
				{
					&:after
					{
						content: none;
					}

					> a
					{
						/*color: $breadcrumb-active-color;*/
					}
				}
			}
		}
	}
</style>
