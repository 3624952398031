export const state = () => ({
  host: '',
  site: '',
  root: ''
});

export const mutations = {
  setHost(state, value) {
    state.host = value;
  },
  setSite(state, value) {
    state.site = value;
  },
  setRoot(state, value) {
    state.root = value;
  }
};

export const actions = {
  loadConfig({ commit }, { host, site, root }) {
    commit('setHost', host);
    commit('setSite', site);
    commit('setRoot', root);
  }
};