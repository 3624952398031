<template>
  <section v-editable="blok" v-if="blok.blocks.length" class="blok-rating">
    <div class="indent" :style="indentStyle"></div>
    <v-layout class="wrap justify-center">
      <v-flex v-for="block in blok.blocks" :key="block._uid" xs12 sm6 md4 lg4>
        <article class="item">

          <div v-if="block.title || block.text" class="content">
            <div class="star-rating">
              <span class="empty"></span>
              <span class="full" :style="{ width: rating(block) }"></span>
            </div>

            <p v-if="block.text" class="text">{{ block.text|replaceText }}</p>

            <h3>{{ block.title|replaceText }}</h3>
          </div>

        </article>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
  export default {
    name: 'Rating',
    props: [
      'story',
      'row',
      'blok'
    ],
    data() {
      return {
        editMode: this.$store.state.editMode || false
      }
    },
    computed: {
      indentStyle() {
        if (this.blok.backgroundColor === 'primary') {
          return {
            backgroundColor: this.$store.state.personalisation.primaryColor && this.$store.state.personalisation.primaryColor.color
          };
        } else if (this.blok.backgroundColor === 'secondary') {
          return {
            backgroundColor: this.$store.state.personalisation.secondaryColor && this.$store.state.personalisation.secondaryColor.color
          };
        }

        return null;
      }
    },
    methods: {
      rating(block) {
        return (block.rating * 10) * 2 + '%';
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~vuetify-scss/bootstrap';

  .blok-rating
  {
    margin: 0;
    padding: 12px 0;

    text-align: left;

    position: relative;
    z-index: 1;

    .indent
    {
      height: 100%;
      width: calc(100% + 52px);

      display: none;

      position: absolute;
      top: 0;
      left: -26px;

      border-radius: 5px;
    }

    .item
    {
      height: 100%;
      width: 100%;

      position: relative;
      z-index: 2;

      background-color: #ffffff;

      border-radius: 5px;

      overflow: hidden;

      > .content
      {
        padding: 15px;

        > .star-rating
        {
          display: inline-block;

          position: relative;

          overflow: hidden;

          span {
            display: inline-block;

            position: absolute;
            left: 0;
            top: 0;

            white-space: nowrap;

            vertical-align: top;

            overflow: hidden;

            &:before {
              content: "\2605\2605\2605\2605\2605";

              font-size: 14pt;
            }

            &.empty {
              position: relative;

              color: #cccccc;
            }
          }
        }

        > .text
        {
          margin-bottom: 5px;

          font-size: 0.9rem;
          font-weight: 400;
          line-height: 1.5;

          white-space: pre-line;
        }

      }
    }
  }

  @include breakpoint(md-and-up)
  {
    .blok-rating
    {
      &.first
      {
        margin-top: -100px;
        padding-top: 14px;

        .indent
        {
          display: block;
        }
      }
    }
  }
</style>
