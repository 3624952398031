import Vue from 'vue'

import BreakdownPage from '@/components/pages/BreakdownPage'
import ContentPage from '@/components/pages/ContentPage'
import FaqOverviewPage from '@/components/pages/FaqOverviewPage'
import FaqSubjectPage from '@/components/pages/FaqSubjectPage'
import FaqQuestionPage from '@/components/pages/FaqQuestionPage'
import LocationPage from '@/components/pages/LocationPage'
import NewsOverviewPage from '@/components/pages/NewsOverviewPage';
import NewsDetailPage from '@/components/pages/NewsDetailPage'

import HeaderLarge from '@/components/bloks/HeaderLarge'
import HeaderSmall from '@/components/bloks/HeaderSmall'
import HeaderVideo from '@/components/bloks/HeaderVideo'

import RowContent from '@/components/rows/RowContent'
import RowFullscreen from '@/components/rows/RowFullscreen'

import Text from '@/components/bloks/Text'
import Video from '@/components/bloks/Video'
import Files from '@/components/bloks/Files'
import Button from '@/components/bloks/Button'
import Table from '@/components/bloks/Table'
import Grid from '@/components/bloks/Grid'
import Rating from '@/components/bloks/Rating'
import Pricing from '@/components/bloks/Pricing'
import News from '@/components/bloks/News'
import FaqOverview from '@/components/bloks/FaqOverview'
import FaqSubject from '@/components/bloks/FaqSubject'
import Search from '@/components/bloks/Search'
import Locations from '@/components/bloks/Locations'
import Breakdown from '@/components/bloks/Breakdown'
import Columns from '@/components/bloks/Columns'
import FullWidthImage from '@/components/bloks/FullWidthImage'

import FormContact from '@/components/forms/FormContact'
import FormRegister from '@/components/forms/FormRegister'

// Pages
Vue.component('blok-page-breakdown', BreakdownPage);
Vue.component('blok-page-content', ContentPage);
Vue.component('blok-page-faq-overview', FaqOverviewPage);
Vue.component('blok-page-faq-subject', FaqSubjectPage);
Vue.component('blok-page-faq-question', FaqQuestionPage);
Vue.component('blok-page-location', LocationPage);
Vue.component('blok-page-news-overview', NewsOverviewPage);
Vue.component('blok-page-news-detail', NewsDetailPage);

// Rows
Vue.component('blok-row-content', RowContent);
Vue.component('blok-row-fullscreen', RowFullscreen);

// Header bloks
Vue.component('blok-header-large', HeaderLarge);
Vue.component('blok-header-small', HeaderSmall);
Vue.component('blok-header-video', HeaderVideo);

// Content bloks
Vue.component('blok-text', Text);
Vue.component('blok-video', Video);
Vue.component('blok-files', Files);
Vue.component('blok-button', Button);
Vue.component('blok-table', Table);
Vue.component('blok-grid', Grid);
Vue.component('blok-rating', Rating);
Vue.component('blok-pricing', Pricing);
Vue.component('blok-news', News);
Vue.component('blok-faq-overview', FaqOverview);
Vue.component('blok-faq-subject', FaqSubject);
Vue.component('blok-search', Search);
Vue.component('blok-locations', Locations);
Vue.component('blok-breakdown', Breakdown);
Vue.component('blok-columns', Columns);
Vue.component('blok-full-width-image', FullWidthImage);

// Forms
Vue.component('blok-form-contact', FormContact);
Vue.component('blok-form-register', FormRegister);