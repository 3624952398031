import Vue from 'vue'

import moment from 'moment';

import {
  getConfigByRoot
} from '@/assets/js/config';

/**
 * Get current root
 * @returns {*|string}
 */
const getRoot = () => {
  return Vue.prototype.$nuxt.$store.state.config.root || '/stadsparkeren';
};

/**
 * Get current site
 * @returns {*|string}
 */
const getSite = () => {
  return Vue.prototype.$nuxt.$store.state.config.site || 'stadsparkeren';
};

/**
 * Get links
 *
 * @returns {{}}
 */
const getLinks = () => {
  return Vue.prototype.$nuxt.$store.state.links || {};
};

/**
 * Check if is editMode
 * @returns {*|boolean}
 */
const isEditMode = () => {
  return Vue.prototype.$nuxt.$store.state.editMode || false;
};

/**
 * Format date
 * @param value
 * @param format
 * @returns {string}
 */
const formatDate = (value, format) => {
  if (typeof value !== 'undefined') {
    return moment(String(value)).format(format);
  }
};

/**
 * Resize image
 * @param image
 * @param size
 * @returns {string|null}
 */
const resize = (image, size) => {
  if (typeof image !== 'undefined') {
    return '//img2.storyblok.com/' + size + image.replace('//a.storyblok.com', '');
  }
  return null;
};

/**
 * Dashify
 * @param value
 * @returns {string}
 */
const dashify = (value) => {
  if (typeof value === 'undefined') {
    return 'undefined';
  }

  let dashified = value.toString()
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .toLowerCase()
    .replace(/[ _]/g, '-');

  return 'blok-' + dashified;
};

/**
 * Capitalize first letter of string
 * @param value
 * @returns {string}
 */
const capitalize = (value) => {
  if (typeof value === 'undefined') {
    return 'undefined';
  }

  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * String url
 *
 * @param url
 * @returns {string|*}
 */
const stringUrl = (url) => {
  if (typeof url === 'undefined') {
    return 'undefined';
  }
  
  if (!isEditMode()) {
    return url;
  }
  else
  {
    const root = getRoot();
  
    return root + url;
  }
};

/**
 * Link field type url
 *
 * @param link
 * @returns {*}
 */
const url = (link) => {
  if (typeof link === 'undefined') {
    return 'undefined';
  }

  if (link.linktype === 'story')
  {
    const links = getLinks();
  
    if (!links[link.id]) {
      return null;
    }
    
    const slug = links[link.id].slug;
    
    return getCorrectedUrl(slug);
  } else {
    return link.url;
  }
};

/**
 * Story url
 *
 * @param uuid
 * @returns {string}
 */
const story_url = (uuid) => {
  if (typeof uuid === 'undefined') {
    return 'undefined';
  }
  
  const links = getLinks();
  
  if (!links[uuid]) {
    return null;
  }
  
  const slug = links[uuid].slug;
  
  return getCorrectedUrl(slug);
};

/**
 * Check if link is defined
 *
 * @param link
 * @returns {boolean}
 */
const is_link = (link) => {
  if (typeof link === 'undefined') {
    return false;
  }
  
  switch (link.linktype) {
    case 'story': {
      const links = getLinks();
      
      return !!links[link.id];
    }
    
    case 'email': return !!link.email;
    
    default: return !!link.url;
  }
};

const getCorrectedUrl = (slug) => {
  const root = getRoot();
  
  slug = `/${slug
    .replace(/^algemeen\//g, `${root.replace(/^\//, '')}/`)
    .replace(/^beheer\//g, `${root.replace(/^\//, '')}/`)
    .replace(/^\/|\/$|^home$/, '')}`;
  
  if (!isEditMode()) {
    const config = getConfigByRoot(slug);
    if (!config) {
      console.warn(`Unable to translate slug ${slug}`);
      return slug;
    }
    
    if (root !== config.root) {
      return `https://${config.host}${slug.replace(config.root, '')}`;
    }
    
    return slug.replace(root, '');
  }
  
  return slug;
};

const replaceText = (template, replacements = []) => {
  const website = getSite();
  
  if ([
    'assen',
    'arnhem',
    'beverwijk',
    'gorinchem',
    'helmond',
    'roosendaal',
    'veenendaal'
  ].includes(website)) {

    if (replacements && replacements[website]) {
      
      let items = replacements[website];
      
      for (let item of items) {
        template = template.replace(
          item.regex,
          item.value
        );
      }
    }
  }
  
  return template;
};


Vue.mixin({methods: {
    formatDate: formatDate,
    replaceText: replaceText,
    resize: resize,
    dashify: dashify,
    capitalize: capitalize,
    stringUrl: stringUrl,
    url: url,
    story_url: story_url,
    is_link: is_link
  }});

Vue.filter('formatDate', formatDate);
Vue.filter('replaceText', replaceText);
Vue.filter('resize', resize);
Vue.filter('dashify', dashify);
Vue.filter('capitalize', capitalize);
Vue.filter('stringUrl', stringUrl);
Vue.filter('url', url);
Vue.filter('story_url', story_url);
Vue.filter('is_link', is_link);

Vue.prototype.$replaceText = replaceText;