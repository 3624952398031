<template>
	<section v-editable="blok" class="row-fullscreen">
		<component v-for="block of blok.blocks" :key="block._uid" :is="block.component|dashify" :story="story" :row="blok" :blok="block"/>
	</section>
</template>

<script>
	export default {
		name: 'RowFullscreen',
		props: [
			'story',
			'blok',
			'indent'
		]
	}
</script>

<style lang="scss" scoped>
	.row-fullscreen
	{
		margin: 0;
		padding: 0;

		background-color: #ffffff;

		&.bg-color-grey
		{
			background-color: #eeeeee;
		}
	}
</style>