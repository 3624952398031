<template>
	<section v-editable="blok" class="blok-pricing">
		<v-layout justify-center wrap>
			<v-flex v-for="(block, index) in blok.blocks" :key="block._uid" xs12 md6>
				<article class="item">
					<div class="content">
						<component v-for="option in block.options" :key="option._uid" :is="option.component" :block="block" :option="option" :bg-color="getColor(index)" />
					</div>
				</article>
			</v-flex>
		</v-layout>
	</section>
</template>

<script>
	import PricingOptionTitle from '@/components/bloks/pricing/PricingOptionTitle'
	import PricingOptionText from '@/components/bloks/pricing/PricingOptionText'
	import PricingOptionProperty from '@/components/bloks/pricing/PricingOptionProperty'
	import PricingOptionButton from '@/components/bloks/pricing/PricingOptionButton'

	export default {
		name: 'Pricing',
		props: [
			'story',
			'row',
			'blok'
		],
		components: {
			PricingOptionTitle: PricingOptionTitle,
			PricingOptionText: PricingOptionText,
			PricingOptionProperty: PricingOptionProperty,
			PricingOptionButton: PricingOptionButton
		},
		methods: {
			getColor(index)
			{
				return index % 2 === 0 ?
					this.$store.state.personalisation.pricingPrimaryColor :
					this.$store.state.personalisation.pricingSecondaryColor;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.blok-pricing
	{
		/*margin: 12px 0;*/
		/*padding: 0;*/

		margin: 0;
		padding: 12px 0;

		text-align: left;

		.item
		{
			height: 100%;
			width: 100%;

			background-color: #ffffff;

			border-radius: 5px;

			overflow: hidden;

			.content
			{
				padding: 20px;

				> div:nth-child(even):not(.pricing-option-button)
				{
					background-color: #eeeeee;
				}
			}
		}
	}
</style>