<template>
	<!-- eslint-disable-next-line vue/require-component-is -->
	<component v-if="isLink" v-bind="linkProps()">
		<slot />
	</component>
</template>

<script>
	export default {
		name: 'SLinkType',
		props: [
			'link',
			'linkText',
			'linkClass',
			'linkStyle'
		],
		computed: {
			isLink()
			{
				return this.link && (typeof this.link === 'string' || this.is_link(this.link));
			}
		},
		methods: {
			linkProps()
			{
				const url = this.link && typeof this.link === 'string' ?
					this.link :
					this.url(this.link);

				if (url.match(/^(http(s)?:\/\/|\/\/)/))
				{
					return {
						is: 'a',
						href: url,
						target: '_blank',
						title: this.linkText || false,
						class: this.linkClass || false,
						style: this.linkStyle || false,
						rel: 'noopener'
					};
				}

				return {
					is: 'router-link',
					to: url,
					title: this.linkText || false,
					class: this.linkClass || false,
					style: this.linkStyle || false,
					exact: url === '/'
				};
			}
		}
	}
</script>
