<template>
	<div class="pagination">
		<div class="prev">
			<nuxt-link v-if="page !== 1" :to="to(page-1)">
				<span class="material-icons">keyboard_arrow_left</span>
			</nuxt-link>
			<span v-else>
				<span class="material-icons">keyboard_arrow_left</span>
			</span>
		</div>

		<div class="indexes">
			<div v-for="index in totalPages" :key="index">
				<nuxt-link v-if="index !== page" :to="to(index)">
					{{ index }}
				</nuxt-link>
				<span v-else>{{ index }}</span>
			</div>
		</div>

		<div class="next">
			<nuxt-link v-if="page !== totalPages" :to="to(page+1)">
				<span class="material-icons">keyboard_arrow_right</span>
			</nuxt-link>
			<span v-else>
				<span class="material-icons">keyboard_arrow_right</span>
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SPagination',
		props: [
			'totalPages'
		],
		computed: {
			page() {
				return parseInt(this.$route.query['page']) || 1;
			}
		},
		methods: {
			to(page) {
				return {...this.$route, query: page !== 1 ? {page: page} : null}
			}

		}
	}
</script>

<style lang="scss">
	@import '~vuetify-scss/bootstrap';

	.pagination
	{
		display: block;

		text-align: center;

		.prev,
		.next,
		.indexes > div
		{
			display: inline-block;

			> a,
			> span
			{
				height: 40px;
				width: 40px;

				display: inline-block;

				margin: 0 5px;
				padding: 0;

				position: relative;
				z-index: 1;

				line-height: 40px;

				font-size: 1.2rem;
				font-weight: 400;
				text-align: center;

				background-color: #ffffff;

				border: 1px solid #d3d3d3;
				border-radius: 3px;

				vertical-align: middle;

				> span.material-icons
				{
					position: absolute;
					top: 50%;
					left: 50%;

					vertical-align: middle;

					transform: translate(-50%, -50%);
				}
			}

			> span
			{
				color: #d3d3d3;
			}
		}

		.prev
		{
			float: left;
		}

		.next
		{
			float: right;
		}

		.indexes
		{
			display: none;
		}
	}

	@include breakpoint(md-and-up)
	{
		.pagination .indexes
		{
			display: inline-block;
		}
	}
</style>
