<template>
	<footer>
		<v-container grid-list-xl>
			<v-layout wrap>
				<v-flex xs12 lg6>
					<div v-if="logoWhite" class="footer-logo">
						<nuxt-link to="/" :title="siteName">
							<img :src="logoWhite" :alt="siteName" />
						</nuxt-link>
					</div>
					<div v-if="isWhiteLabel" class="footer-powered-by">
						<ul>
							<li>
								<a href="https://stadsparkeren.nl" title="Stadsparkeren" target="_blank">
									Powered by Stadsparkeren
								</a>
							</li>
						</ul>
					</div>
				</v-flex>
				<v-flex xs12 lg3>
					<nav v-if="items.length" class="footer-nav">
						<ul>
							<s-link-wrapper v-for="item in items" :key="item._uid" :tag="'li'" :link="item.link" :linkText="item.name">
								{{ item.name }}
							</s-link-wrapper>
						</ul>
					</nav>
				</v-flex>
				<v-flex xs12 lg3>
					<s-markdown
						:tag="'div'"
						:value="contact"
						class="footer-contact"
					/>
					<div v-if="facebook || twitter || linkedin || instagram" class="footer-social">
            <ul>
              <li>
                <a v-if="facebook" :href="facebook" title="Facebook">
                  <span class="icon-facebook"></span>
                </a>
              </li>
              <li>
                <a v-if="twitter" :href="twitter" title="Twitter">
                  <span class="icon-twitter"></span>
                </a>
              </li>
              <li>
                <a v-if="linkedin" :href="linkedin" title="LinkedIn">
                  <span class="icon-linkedin"></span>
                </a>
              </li>
              <li>
                <a v-if="instagram" :href="instagram" title="Instagram">
                  <span class="icon-instagram"></span>
                </a>
              </li>
            </ul>
					</div>
					<div class="footer-apps">
						<ul>
              <li>
                <a href="https://itunes.apple.com/us/app/stadsparkeren/id892308309?ls=1&mt=8" target="_blank" title="Stadsparkeren iPhone app">
                  <span class="icon-apple" title="Stadsparkeren iPhone app"></span>
                </a>
              </li>
              <li>
                <a href="https://play.google.com/store/apps/details?id=nl.stadsparkeren" target="_blank" title="Stadsparkeren Android app">
                  <span class="icon-android" title="Stadsparkeren Android app"></span>
                </a>
              </li>
						</ul>
					</div>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12>
					<div class="footer-copyright">
						<ul>
							<li>{{ siteName }} {{ year }}</li>
							<li>
								<nuxt-link :to="stringUrl('/algemene-voorwaarden')">
									Algemene voorwaarden
								</nuxt-link>
							</li>
							<li>
								<nuxt-link :to="stringUrl('/privacyverklaring')">
									Privacyverklaring
								</nuxt-link>
							</li>
              <li>
                <nuxt-link :to="stringUrl('/cookies')">
                  Cookies
                </nuxt-link>
              </li>
						</ul>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</footer>
</template>

<script>
	import moment from 'moment';

	import SLinkWrapper from '@/components/util/SLinkWrapper';
	import SMarkdown from '@/components/util/SMarkdown';

	export default {
		name: 'Footer',
		components: {
			SLinkWrapper: SLinkWrapper,
			SMarkdown: SMarkdown
		},
		data() {
			return {
			}
		},
		computed: {
			logoWhite() {
				return this.$store.state.settings && this.$store.state.personalisation.logoWhite || false;
			},
			siteName() {
				return this.$store.state.settings && this.$store.state.settings.siteName || false;
			},
			items() {
				return this.$store.state.settings && this.$store.state.settings.footerNav || [];
			},
			contact() {
				return this.$store.state.settings && this.$store.state.settings.footerContact || '';
			},
			facebook() {
				return this.$store.state.settings && this.$store.state.settings.facebook || '';
			},
			twitter() {
				return this.$store.state.settings && this.$store.state.settings.twitter || '';
			},
			linkedin() {
				return this.$store.state.settings && this.$store.state.settings.linkedin || '';
			},
			instagram() {
				return this.$store.state.settings && this.$store.state.settings.instagram || '';
			},
			year() {
				return moment().year();
			},
			isWhiteLabel() {
				return this.$store.state.config && [
					'arnhem',
					'assen',
					'beverwijk',
					'gorinchem',
					'helmond',
					'roosendaal',
					'veenendaal'
				].includes(this.$store.state.config.site);
			}
		},
	}
</script>

<style lang="scss">
	@import '~vuetify-scss/bootstrap';

	footer
	{
		margin: 0;
		padding: 30px 0;

		.footer-logo
		{
			height: 100px;

			margin-bottom: 20px;

			> img,
			> a > img
			{
				height: 100%;
			}
		}

		.footer-nav,
		.footer-contact,
		.footer-copyright
		{
			color: #ffffff;
		}

		.footer-nav
		{
			ul
			{
				margin: 0;
				padding: 0;

				list-style: none;

				> li
				{
					display: block;

					> a
					{
						display: inline-block;

						position: relative;

						margin: 0;
						padding: 0;

						color: #ffffff;

						text-decoration: none;

						&:before
						{
							content: '';

							height: 1px;
							width: 0;

							position: absolute;
							left: 50%;
							bottom: 0;

							background-color: #ffffff;

							transition: width 0.1s, left 0.1s;
						}

						&:hover,
						&:focus
						{
							&:before
							{
								width: 100%;

								left: 0;
							}
						}
					}
				}
			}
		}

		.footer-contact
		{
			p
			{
				margin: 0;
				padding: 0;

				> a
				{
					color: #ffffff;
				}
			}
		}

		.footer-social
		{
			margin: 0;
			padding: 12px 0;

      ul
      {
        height: 30px;

        margin: 0;
        padding: 0;

        list-style: none;

        > li
        {
          height: 30px;

          position: relative;

          display: inline-block;

          line-height: 40px;

          vertical-align: top;

          > a
          {
            height: 30px;
            width: 30px;

            display: inline-block;

            color: #ffffff;
            line-height: 30px;

            text-align: center;

            vertical-align: top;
          }
        }
      }
		}

		.footer-apps
		{
      margin: 0;
      padding: 12px 0;

			ul
			{
        height: 30px;

				margin: 0;
				padding: 0;

				list-style: none;

				> li
				{
					height: 30px;

					position: relative;

					display: inline-block;

					line-height: 40px;

          vertical-align: top;

          > a
          {
            height: 30px;
            width: 30px;

            display: inline-block;

            color: #ffffff;
            line-height: 30px;

            text-align: center;

            vertical-align: top;
          }
				}
			}
		}

		.footer-copyright,
		.footer-powered-by
		{
			ul
			{
				margin: 0;
				padding: 0;

				list-style: none;

				> li
				{
					height: 40px;

					position: relative;

					display: block;

					margin: 0;
					padding: 0;

					line-height: 40px;

					vertical-align: middle;

					&:first-child
					{
						margin-left: 0;
					}

					&:last-child
					{
						margin-right: 0;
					}

					> a
					{
						color: #ffffff;
					}
				}
			}
		}
	}

	@include breakpoint(md-and-up)
	{
		footer
		{
			.footer-copyright,
			.footer-powered-by
			{
				ul
				{
					> li {
						display: inline-block;

						margin: 0 10px;

						&:after
						{
							content: '-';

							position: absolute;
							top: 50%;
							right: -15px;

							transform: translateY(-50%);
						}

						&:last-child:after
						{
							content: none;
						}
					}
				}
			}
		}
	}
</style>
