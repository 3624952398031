import {darken} from 'assets/js/utils/color';

export default (personalisation, fonts) =>  `
.v-application h1,
.v-application h2,
.v-application h3,
.v-application h4,
.v-application h5,
.v-application h6,
.v-application h1 a,
.v-application h2 a,
.v-application h3 a,
.v-application h4 a,
.v-application h5 a,
.v-application h6 a,
.v-application .h1-title,
.v-application .h2-title,
.v-application .h3-title,
.v-application .h4-title,
.v-application .h5-title,
.v-application .h6-title {
	color: ${personalisation.headingColor ? personalisation.headingColor.color : '#0ebef8'};
	font-family: ${fonts[personalisation.headingFont]};
}

.v-application {
	color: ${personalisation.textColor ? personalisation.textColor.color : '#2c2c2c'} !important;
	font-family: ${fonts[personalisation.textFont]} !important;
	line-height: 1.75 !important;
}

.v-application .top .quick-menu ul li a.icon span {
  color: ${personalisation.primaryColor ? personalisation.primaryColor.color : '#4fb127'};
}

.v-application .blok-faq-overview .faq-latest .item .content ul li a,
.v-application .blok-faq-overview .faq-subjects .item .content ul li a {
	color: ${personalisation.textColor ? personalisation.textColor.color : '#2c2c2c'};
}

.v-application .blok-locations .locations-list .locations-list-results ul li a {
	color: ${personalisation.textColor ? personalisation.textColor.color : '#2c2c2c'};
}

.v-application .blok-locations .locations-list .locations-list-results ul li a .payment .icon {
  color: ${personalisation.primaryColor ? personalisation.primaryColor.color : '#4fb127'};
}

.v-application .blok-rating .item .content .star-rating span.full {
  color: ${personalisation.primaryColor ? personalisation.primaryColor.color : '#4fb127'};
}

.v-application a,
.v-application .read-more {
	color: ${personalisation.linkColor ? personalisation.linkColor.color : '#0ebef8'};
	font-family: ${fonts[personalisation.textFont]};
}

.v-application .btn {
	font-family: ${fonts[personalisation.buttonFont]};
	background-color: ${personalisation.buttonBgColor ? personalisation.buttonBgColor.color : '#4fb127'};
}

.v-application .date {
	font-family: ${fonts[personalisation.dateFont]};
	background-color: ${personalisation.dateBgColor ? personalisation.dateBgColor.color : '#0ebef8'};
}

.v-application .nav,
.v-application .nav .main-nav,
.v-application .nav .main-nav-close-btn {
	background-color: ${personalisation.navBgColor ? personalisation.navBgColor.color : '#333333'};
}

.v-application .nav .main-nav ul li a {
	font-family: ${fonts[personalisation.navFont]};
}

.v-application .nav.mobile .main-nav ul li a.highlight,
.v-application .nav.desktop .main-nav ul li a.highlight {
	font-family: ${fonts[personalisation.navFont]};
	background-color: ${personalisation.navButtonBgColor ? personalisation.navButtonBgColor.color : '#4fb127'};
}

.v-application .nav.mobile .main-nav ul li a.highlight:hover,
.v-application .nav.mobile .main-nav ul li a.highlight:focus,
.v-application .nav.mobile .main-nav ul li a.highlight.nuxt-link-exact-active,
.v-application .nav.desktop .main-nav ul li a.highlight:hover,
.v-application .nav.desktop .main-nav ul li a.highlight:focus,
.v-application .nav.desktop .main-nav ul li a.highlight.nuxt-link-exact-active {
	background-color: ${personalisation.navButtonBgColor ? darken(personalisation.navButtonBgColor.color, 10) : darken('#4fb127', 10)};
}

.v-application footer {
	background-color: ${personalisation.footerBgColor ? personalisation.footerBgColor.color : '#4fb127'};
}
`;
